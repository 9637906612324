import classNames from "classnames";
import { useEffect, useState } from "react";
import { LeapFrog } from "@uiball/loaders";
import { useTranslation } from "react-i18next";
import { delay } from "../../../consts/delay";

import styles from "./Loading.module.scss";

interface LoadingProps {
  className?: string;
  withText?: boolean;
  style?: object;
  customDelay?: number;
  position?: "default" | "absoluteCenter";
}

const Loading = ({
  className,
  withText = true,
  style,
  customDelay,
  position = "default",
}: LoadingProps) => {
  const { t } = useTranslation("global");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const setDelay = async () => {
      await delay(customDelay || 300);
      setLoading(true);
    };
    setDelay();
  }, [customDelay]);

  if (loading) {
    return (
      <div style={style} className={classNames(styles.root, styles[position], className)}>
        <LeapFrog color='#0F8594' />
        {withText && <span className={styles.text}>{t("loading")}</span>}
      </div>
    );
  } else {
    return <div />;
  }
};

export default Loading;
