import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames/bind";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";
import { useTranslation } from "react-i18next";
import { Space } from "antd";
import { useQuery } from "@tanstack/react-query";

import {
  addRequestsComment,
  approveRequest,
  attachExistingFile,
  deleteRequest,
  detachDocument,
  getRequest,
  rejectRequest,
  sendRequest,
  upDateRequests,
} from "../../../../../api/requestsPage";
import { getDocumentBacklinks } from "../../../../../api/documentApi";
import { useFetch } from "../../../../../hooks";
import {
  REQUEST_STATUSES,
  REQUEST_TYPE,
  ROLES_REQUSTED_TEXT,
  STATUS_COLORS,
} from "../../../../../consts/enums";
import { prepareDraft } from "../../../../../consts/prepareDraft";
import Attachments from "../../../components/Attachments/Attachments";

import {
  AssignedWrapper,
  CommentsViewer,
  HeaderEditor,
  MenuView,
  PageTextEditor,
} from "../../../components";
import { Button, Label, Loading, Tag } from "../../../../../components/ui-components";
import DueDateTime from "../../../components/DueDateTime/DueDateTime";

import DeleteModal from "../../../../../components/DeleteModal";

import styles from "./RequestView.module.scss";

const cx = classNames.bind(styles);

const initEditMode = { name: false, description: false };

const RequestView = ({ handleDeleteAction, changeCurrentRequest, hasAdminPermissions }) => {
  const { requestId, id: auditId } = useParams();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "requests" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [requestData, setRequestData] = useState(null);
  const [editRequestData, setEditRequestData] = useState({
    name: "",
    description: "",
  });
  const [comment, setComment] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lastFetchDate, setLastFetchDate] = useState(null);
  const [errors, setErrors] = useState({});

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { isLoading, error, data } = useFetch(getRequest, auditId, requestId);
  const { data: documents } = useQuery({
    staleTime: Infinity,
    queryKey: ["documents"],
    queryFn: () => getDocumentBacklinks(auditId),
  });

  const [editMode, setEditMode] = useState(initEditMode);

  useEffect(() => {
    if (data && !error && !isLoading) {
      setLastFetchDate(data.lastFetched);
      setRequestData(data.request);
      const { name, description } = data.request;
      setEditRequestData({ name, description });
      setEditorState(prepareDraft(description));
    }
  }, [data, isLoading, error]);

  useEffect(() => {
    setEditMode(initEditMode);
  }, [requestId]);

  const updateRequest = async (body, config) => {
    try {
      const corruptedKeys = {
        assignedUsers: "assignedUsers",
        assignedRoles: "assignedRoles",
        attachments: "relatedDocs",
      };
      const hasCorruptedKeys = Object.keys(corruptedKeys).some((key) => body.hasOwnProperty(key));
      if (!hasCorruptedKeys) {
        changeCurrentRequest(requestId, body);
      }
      const response = await upDateRequests(body, auditId, requestId, config);
      if (hasCorruptedKeys) {
        const values = Object.values(corruptedKeys).reduce((acc, val) => {
          if (response.request.hasOwnProperty(val)) {
            acc[val] = response.request[val];
          }
          return acc;
        }, {});
        changeCurrentRequest(requestId, values);
      }
      return response;
    } catch (err) {
      alert(err.message);
    }
  };

  const handleEditRequestData = (e) => {
    const { name, value } = e.target;
    setEditRequestData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAssignedUsersAndRoles = (assignedUsers = [], assignedRoles = []) => {
    setRequestData((prevValue) => ({
      ...prevValue,
      assignedUsers,
      assignedRoles,
    }));
  };

  const openEditMode = (name) => {
    setEditMode((prev) => ({ ...prev, [name]: true }));
  };

  const closeEditMode = (event, name) => {
    event.stopPropagation();
    setEditMode((prev) => ({ ...prev, [name]: false }));
  };

  const addComment = async (event) => {
    if (comment) {
      if (event.key === "Enter") {
        try {
          const response = await addRequestsComment({ text: comment }, auditId, requestId);
          setRequestData((prev) => ({
            ...prev,
            comments: [...prev.comments, response.comment],
          }));
        } catch (err) {
          console.error(err);
        }
        setComment("");
      }
    }
  };

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const onEditorStateChange = (editorState) => {
    if (editMode.description) {
      const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      setEditRequestData((prev) => ({
        ...prev,
        description: forFormik,
      }));
      setEditorState(editorState);
    }
  };

  const handleDelete = () => {
    deleteRequest(auditId, requestId).then(() => {
      handleDeleteAction();
    });
  };

  const acceptChanges = async (event, name) => {
    closeEditMode(event, name);
    await updateRequest({ [name]: editRequestData[name] });
    setRequestData((prev) => ({
      ...prev,
      [name]: editRequestData[name],
    }));
  };
  const discardChanges = async (event, name) => {
    setEditRequestData((prev) => ({
      ...prev,
      [name]: requestData[name],
    }));
    closeEditMode(event, name);
  };

  const handleAssignedUsers = async ({ assignedUsers, assignedRoles }) => {
    setErrors({});
    handleAssignedUsersAndRoles(assignedUsers, assignedRoles);

    await updateRequest({
      assignedUsers: assignedUsers.map((user) => user.id),
      assignedRoles: assignedRoles.map((role) => role.id),
    });
  };

  const removeAssignee = async (type, userId) => {
    const newAssignees = requestData[type].filter((user) => user.id !== userId);

    setRequestData((prevValue) => ({
      ...prevValue,
      [type]: newAssignees,
    }));

    await updateRequest({
      [type]: newAssignees.map((user) => user.id),
    });
  };

  const openFullEdit = () => history.push(`/dashboard/${auditId}/requests/edit`, requestData);

  const addAttachmentFile = async (files) => {
    setRequestData((prev) => ({
      ...prev,
      attachments: [...(prev.attachments || []), files[0]],
    }));
    const res = await updateRequest(
      { attachments: [files[0]] },
      {
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent, "progress");
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          console.log(progress, "progress");
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          console.log(progressEvent, "progress");
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
          console.log(progress, "progress");
          setProgress(progress);
        },
      }
    );
    // logically it shouldn't be only res.request, but backend returns request object both
    // for request and meeting, should be fixed
    setRequestData(res.request);
    setProgress(0);
  };

  const handleDeleteDocument = async (id) => {
    try {
      await detachDocument(auditId, requestId, id);
      setRequestData((prev) => ({
        ...prev,
        relatedDocs: prev.relatedDocs.filter((el) => el.id !== id),
      }));
    } catch (e) {
      console.log(e);
    }
  };

  const onSendRequest = async () => {
    if (!requestData.assignedUsers.length && !requestData.assignedRoles.length) {
      setErrors({ assignedUsers: "Required field" });
      return;
    }
    try {
      await sendRequest(auditId, requestId);
      setRequestData((prev) => ({
        ...prev,
        status: "sent",
      }));
      changeCurrentRequest(requestId, { status: "sent" }, requestData.status);
    } catch (err) {
      console.error(err);
    }
  };

  const onRejectRequest = async () => {
    if (requestData.status !== REQUEST_STATUSES.SENT) {
      console.log("cannot edit");
      return;
    }
    try {
      await rejectRequest(auditId, requestData, requestId);
      changeCurrentRequest(requestId, { status: "requested" }, requestData.status);
      setRequestData((prev) => ({
        ...prev,
        status: "requested",
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const onApproveRequest = async () => {
    try {
      await approveRequest(auditId, requestId);
      setRequestData((prev) => ({
        ...prev,
        status: "approved",
      }));
      changeCurrentRequest(requestId, { status: "approved" }, requestData.status);
    } catch (err) {
      console.error(err);
    }
  };

  const changeDateTime = (newState) => {
    setRequestData((state) => ({
      ...state,
      ...newState,
    }));
  };

  const attachExistingDocument = async (name, document) => {
    setRequestData((state) => ({
      ...state,
      relatedDocs: [...state.relatedDocs, document],
    }));
    await attachExistingFile(auditId, requestId, { documentId: document.id });
  };

  if (error) {
    return <div> Something went wrong, please try again later</div>;
  }

  const isDisabled = requestData?.status === REQUEST_STATUSES.APPROVED;
  return !isLoading && requestData && editRequestData ? (
    <div className={styles.wrapper}>
      <div className={styles.topContainer}>
        <div className={styles.headerSection}>
          <HeaderEditor
            activeEdit={editMode.name}
            discardChanges={discardChanges}
            acceptChanges={acceptChanges}
            onOpenEdit={openEditMode}
            name='name'
            onChange={handleEditRequestData}
            value={editRequestData.name}
            disabled={isDisabled}
          >
            <Tag color={STATUS_COLORS[requestData.status]}>
              {tGlobal(ROLES_REQUSTED_TEXT[requestData.status] || " ")}
            </Tag>
          </HeaderEditor>

          <Space>
            {(requestData.status === REQUEST_STATUSES.REQUESTED ||
              requestData.status === REQUEST_STATUSES.REJECTED) && (
              <Button handleClick={onSendRequest} color={"green"} primary style={{ width: 133 }}>
                {t("send")}
              </Button>
            )}
            {requestData.status === REQUEST_STATUSES.SENT && hasAdminPermissions && (
              <>
                <Button
                  handleClick={onRejectRequest}
                  color={"red"}
                  secondary
                  style={{ width: 133 }}
                >
                  Reject
                </Button>
                <Button
                  handleClick={onApproveRequest}
                  color={"green"}
                  primary
                  style={{ width: 133 }}
                >
                  Approve
                </Button>
              </>
            )}
            {!isDisabled && (
              <MenuView
                onOpenEdit={openFullEdit}
                onDelete={() => setConfirmDelete(true)}
                editText={t("edit_request")}
                deleteText={t("delete_request")}
              />
            )}
          </Space>
        </div>
        <div className={styles.bodySection}>
          <div className={styles.containerAssigned}>
            <AssignedWrapper
              errors={errors}
              creatorLabel={tGlobal("created_by")}
              assignedLabel={tGlobal("assigned_to")}
              assignButtonLabel={tGlobal("assign_user")}
              creator={requestData?.addedBy}
              assignedRoles={requestData.assignedRoles}
              assignedUsers={requestData.assignedUsers}
              onRemove={removeAssignee}
              handleAssignUsers={handleAssignedUsers}
              disabled={isDisabled}
            />
          </div>
          <div className={cx(styles.row, styles.dateFileWrapper)}>
            <DueDateTime
              dueDate={requestData.dueDate}
              dueTime={requestData.dueTime}
              setData={changeDateTime}
              onChange={updateRequest}
              disabled={isDisabled}
            />
          </div>
          <PageTextEditor
            label={t("description")}
            name='description'
            discardChanges={discardChanges}
            acceptChanges={acceptChanges}
            openEditMode={openEditMode}
            value={editRequestData.description}
            isEditMode={editMode.description}
            editorState={editorState}
            disabled={isDisabled}
            readOnly={!editMode.description}
            onEditorStateChange={onEditorStateChange}
          />
          <Attachments
            progress={progress}
            isBoxioRequest={requestData.request_type === REQUEST_TYPE.DATA_TRANSFER_REQUEST}
            relatedDocs={requestData.relatedDocs}
            attachments={requestData.attachments}
            last_fetched={requestData.last_fetched}
            bexio_refresh={requestData.bexio_refresh}
            lastFetchDate={lastFetchDate}
            hasAdminPermissions={hasAdminPermissions}
            onRemoveFile={handleDeleteDocument}
            onAddFile={addAttachmentFile}
            onAttach={attachExistingDocument}
            searchOptions={documents || []}
            hasSearch
            disabled={isDisabled}
          />
          <div className={styles.container}>
            <Label>{tGlobal("log_and_comment")}</Label>
            <CommentsViewer
              tKeyPrefix='requests'
              value={comment}
              onChange={handleComment}
              onSubmit={addComment}
              comments={requestData.comments}
              createdAt={requestData.createdAt}
            />
          </div>
        </div>
      </div>
      <DeleteModal
        open={confirmDelete}
        title={t("delete_request")}
        onCancel={() => setConfirmDelete(false)}
        content={t("delete_request_confirmation_message", { requestName: requestData.name })}
        onDelete={handleDelete}
      />
    </div>
  ) : (
    <div className={styles.loadingWrapper}>
      <Loading />
    </div>
  );
};

export default RequestView;
