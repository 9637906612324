import i18next from "i18next";

export const STATUS_FILTER_OPTIONS = [
  {
    label: i18next.t("dashboard:status:auditor_tasks"),
    value: "auditor",
    color: "gray",
  },
  {
    label: i18next.t("dashboard:status:client_tasks"),
    value: "client",
    color: "blue",
  },
];

export const STATUS_TRANSLATION = {
  Election: i18next.t("dashboard:status:election"),
  Planning: i18next.t("dashboard:status:planning"),
  Execution: i18next.t("dashboard:status:execution"),
  Closing: i18next.t("dashboard:status:closing"),
  Reporting: i18next.t("dashboard:status:reporting"),
};

export const OWNERSHIP_OPTIONS = [
  {
    value: "client",
    name: i18next.t("dashboard:status:client_task"),
    description: i18next.t("dashboard:status:client_task_description"),
  },
  {
    value: "auditor",
    name: i18next.t("dashboard:status:auditor_task"),
    description: i18next.t("dashboard:status:auditor_task_description"),
  },
];

export const TASK_TYPE_BY_OWNERSHIP = {
  client: [
    {
      value: "simple",
      name: i18next.t("dashboard:status:client_task_type_simple"),
      description: i18next.t("dashboard:status:client_task_type_simple_description"),
    },
    {
      value: "request",
      name: i18next.t("dashboard:status:client_task_type_request"),
      description: i18next.t("dashboard:status:client_task_type_request_description"),
    },
    {
      value: "signature",
      name: i18next.t("dashboard:status:client_task_type_signature"),
      description: i18next.t("dashboard:status:client_task_type_signature_description"),
    },
    {
      value: "meeting",
      name: i18next.t("dashboard:status:client_task_type_meeting"),
      description: i18next.t("dashboard:status:client_task_type_meeting_description"),
    },
  ],
  auditor: [
    {
      value: "general",
      name: i18next.t("dashboard:status:auditor_task_type_general"),
      description: i18next.t("dashboard:status:auditor_task_type_general_description"),
    },
    {
      value: "analytics",
      name: i18next.t("dashboard:status:auditor_task_type_analytics"),
      description: i18next.t("dashboard:status:auditor_task_type_analytics_description"),
    },
    {
      value: "inquiry",
      name: i18next.t("dashboard:status:auditor_task_type_inquiry"),
      description: i18next.t("dashboard:status:auditor_task_type_inquiry_description"),
    },
    {
      value: "test_of_details",
      name: i18next.t("dashboard:status:auditor_task_type_test_of_details"),
      description: i18next.t("dashboard:status:auditor_task_type_test_of_details_description"),
    },
  ],
};
