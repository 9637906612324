import { Progress } from "antd";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18next from "i18next";

import {
  createPreset,
  disconnectFromPreset,
  pullPreset,
  savePreset,
  setPreset,
  updatePreset,
} from "../../../../../api/statusApi";
import useQueryParams from "../../../../../hooks/useQueryParams";
import calculateExactTimeDifference from "../../../../../utils/calculateExactTimeDifference";

import CustomDatePicker from "../../../../../components/ui-components/CustomDatePicker/CustomDatePicker";
import { Button, Input, Tag } from "../../../../../components/ui-components";
import Warning from "../../../../../components/ui-components/Warning/Warning";
import CustomPresetDropdown from "../../../../../components/ui-components/CustomPresetDropdown/CustomPresetDropdown";
import CustomAntDropdown from "../../../../../components/ui-components/CustomAntDropdown/CustomAntDropdown";
import FilterTasksOverlay from "./FilterTasksOverlay";

import { PenIcon, StepGroupFinishedIcon } from "../../../../../assets/icons";

import styles from "./StatusList.module.scss";
import { STATUS_FILTER_OPTIONS, STATUS_TRANSLATION } from "../../Status.constants";

dayjs.extend(relativeTime);

const initTemplateOptions = [
  // {
  //   label: i18next.t("dashboard:global:create_new_template"),
  //   value: "custom",
  //   hidden: true,
  // },
  {
    label: i18next.t("dashboard:global:no_template"),
    value: null,
  },
];

const StatusList = ({
  data,
  setData,
  activeGroup,
  syncData,
  setActiveGroup,
  editMode,
  setEditMode,
  companyPresets,
  setUpdatedSteps,
  previewPreset,
  setPreview,
  isUnsynced,
  hasAdminPermissions,
  filter,
  setFilter,
  hasAnyStatusProgress,
}) => {
  const { id: idAudit } = useParams();
  const { query, setQueryParams, removeQueryParams } = useQueryParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const allSteps = data?.statusGroups?.reduce((acc, item) => [...acc, ...item.steps], []);
  const doneStepAmount = allSteps.filter((i) => i.status === "done").length;
  const progress = (doneStepAmount / allSteps.length) * 100 || 0;
  const statusGroupAmount = data.statusGroups?.length || 0;

  const [loading, setLoading] = useState(false);
  const [customType, setCustomType] = useState("");
  const [customTypeError, setCustomTypeError] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(
    data.preset?.auditType ? data.preset.auditType : null
  );
  const [templateOptions, setTemplateOptions] = useState([
    ...companyPresets.map((i) => ({
      label: i.auditType,
      value: i.auditType,
      actions: true,
    })),
    ...initTemplateOptions,
  ]);
  const date = data?.reportDate ? dayjs(data.reportDate) : null;

  const handleChangeData = async (name, value) => {
    setCurrentTemplate(value);
    if (value === "custom" || data.preset?.auditType === value || value === null) {
      setPreview(null);
      setActiveGroup(null);
      return;
    }

    await previewPreset(value);
  };

  const handleChangeDate = (value) => {
    const newDate = new Date(value).toISOString();
    syncData({ reportDate: newDate });
  };

  const handleChangeFilter = (name, value) => {
    setFilter((state) => [...state, value]);
  };

  const handleTypeChange = (e) => {
    const { value } = e.target;

    setCustomType(value);
  };

  const startEdit = async () => {
    setEditMode(true);
    const newData = await savePreset(idAudit);
    setData(newData);
    setUpdatedSteps({});
    setQueryParams({ edit: "true" });
  };

  const handleCancel = async () => {
    try {
      setLoading(true);
      setCustomTypeError(false);
      setCustomType("");
      setPreview(null);
      setActiveGroup(null);
      removeQueryParams(["edit", "activeGroup"]);

      // TODO: IT"S ONLY NEEDED IF DELETE IS NOT EXECUTED THROUGH "SAVE" BUTTON
      // TODO: BUT IT"S BEING EXECUTED INSTANLY ON "DELETE" BUTTON
      const newData = await savePreset(idAudit);
      setCurrentTemplate(newData.audit.preset?.auditType || null);
      setData(newData);
      setEditMode(false);
      setUpdatedSteps({});
    } finally {
      setLoading(false);
    }
  };

  const handleSetActiveGroup = (group) => {
    setActiveGroup(group);
    setQueryParams({ activeGroup: group });
  };

  const handleSave = async () => {
    if (currentTemplate === "custom" && !customType) {
      setCustomTypeError(true);
      return;
    }
    try {
      setLoading(true);
      if (currentTemplate === "custom") {
        // create new template
        setCurrentTemplate(customType);
        setTemplateOptions((state) => [
          {
            label: customType,
            value: customType,
            actions: true,
          },
          ...state,
        ]);
        await createPreset(idAudit, { preset: customType });
      } else if (
        data.preset ? data.preset?.auditType !== currentTemplate : currentTemplate !== data.preset
      ) {
        await setPreset(idAudit, { preset: currentTemplate });
      }
      const body = {
        steps: data.statusGroups
          .map((group) =>
            group.steps.filter((i) => i.editModeAction || i.subtasks?.some((i) => i.editModeAction))
          )
          .flat(),
      };
      await updatePreset(idAudit, body);
      setCustomTypeError(false);
      setCustomType("");
      setEditMode(false);

      const newData = await savePreset(idAudit);
      setPreview(null);
      setData(newData);
      setUpdatedSteps({});
      removeQueryParams(["edit", "activeGroup"]);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePreset = async () => {
    try {
      setLoading(true);
      const body = {
        steps: data.statusGroups.map((group) => group.steps.filter((i) => i.editModeAction)).flat(),
      };
      await updatePreset(idAudit, body);
      setEditMode(false);

      // TODO: REMOVE SAVE FUNCTIONALITY SINCE THE SAVE IS SYNCED
      // TODO: BUT NEED TO ADD FUNCTIONALITY FROM SAVE ON THE SYNC-SAVE ENDPOINT
      const newData = await savePreset(idAudit);
      setData(newData);
      setUpdatedSteps({});
      removeQueryParams(["edit", "activeGroup"]);
    } finally {
      setLoading(false);
    }
  };

  const handlePullPreset = async () => {
    try {
      setLoading(true);
      await pullPreset(idAudit);
      setEditMode(false);

      // TODO: REMOVE SAVE FUNCTIONALITY SINCE THE SAVE IS SYNCED
      // TODO: BUT NEED TO ADD FUNCTIONALITY FROM SAVE ON THE SYNC-SAVE ENDPOINT
      const newData = await savePreset(idAudit);
      setData(newData);
      setUpdatedSteps({});
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnectPreset = async () => {
    try {
      setLoading(true);
      const body = {
        steps: data.statusGroups.map((group) => group.steps.filter((i) => i.editModeAction)).flat(),
      };
      setCurrentTemplate(null);
      const res = await disconnectFromPreset(idAudit, body);
      delete res.statusGroups;
      setData(res);
      // setEditMode(false);
      // const newData = await savePreset(idAudit);
      // setData(newData);
      // setUpdatedSteps({});
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const hasActiveChanges = useMemo(() => {
    return (
      currentTemplate &&
      data.statusGroups?.some((group) => {
        return group?.steps?.some(
          (step) => step.editModeAction || step.subtasks?.some((i) => i.editModeAction)
        );
      })
    );
  }, [data.statusGroups, currentTemplate]);

  const filteredTemplateOptions = useMemo(() => {
    return doneStepAmount > 0 || isUnsynced || hasActiveChanges
      ? templateOptions.map((i) => {
          if (i.value === data.preset?.auditType) {
            return i;
          }

          if (i.value === null) {
            return i;
          }

          return { ...i, active: false };
        })
      : templateOptions;
  }, [
    templateOptions,
    doneStepAmount,
    data.preset?.auditType,
    data.isAuditUpToDate,
    data.isTemplateUpToDate,
    hasActiveChanges,
  ]);

  const filteredFilterOptions = useMemo(() => {
    return STATUS_FILTER_OPTIONS.filter((i) => !filter.includes(i.value));
  }, [filter]);

  const removeFilterValue = (value) => {
    setFilter((state) => state.filter((i) => i !== value));
  };

  useEffect(() => {
    if (query.edit) {
      startEdit();
    }
    if (query.activeGroup) {
      setActiveGroup(+query.activeGroup);
    }
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span className={styles.title}>{tGlobal("status")}</span>
        {/*{memberRole === MEMBER_ROLES.AUDITOR && !editMode && (*/}
        {hasAdminPermissions && (
          <div className={styles.row}>
            <CustomAntDropdown
              placeholder={tGlobal("filter")}
              options={filteredFilterOptions}
              value={filter}
              name='filter'
              style={{ width: "87px" }}
              headerStyle={{ padding: "9.3px 16px" }}
              dropdownStyle={{ width: "223px" }}
              CustomOverlay={FilterTasksOverlay}
              onChange={handleChangeFilter}
            />
            {!editMode && (
              <Button secondary icon={<PenIcon />} handleClick={startEdit} color='gray' />
            )}
          </div>
        )}
      </div>
      {filter?.length > 0 && (
        <div className={styles.filterRow}>
          {filter.map((i) => {
            const tag = STATUS_FILTER_OPTIONS.find((j) => j.value === i);
            return (
              <Tag color={tag.color} onClose={() => removeFilterValue(i)}>
                {tag.label}
              </Tag>
            );
          })}
        </div>
      )}
      <div className={styles.auditProgress}>
        {editMode ? (
          <>
            <div className={styles.field}>
              <span className={styles.key}>{t("edit_status_page")}</span>
              <div className={styles.row}>
                <Button secondary color='red' disabled={loading} handleClick={handleCancel}>
                  {tGlobal("cancel")}
                </Button>
                <Button
                  primary
                  color='green'
                  handleClick={handleSave}
                  disabled={((isUnsynced || hasActiveChanges) && data.preset) || loading}
                >
                  {tGlobal("save")}
                </Button>
              </div>
            </div>
            <div className={styles.field}>
              <span className={styles.key}>{tGlobal("template")}</span>
              <CustomPresetDropdown
                setCurrentTemplate={setCurrentTemplate}
                setTemplateOptions={setTemplateOptions}
                options={filteredTemplateOptions}
                value={currentTemplate}
                onChange={handleChangeData}
                name='type'
                style={{ width: "100%" }}
                dropdownStyle={{ width: "100%" }}
              />
              {currentTemplate === "custom" && (
                <Input
                  value={customType}
                  error={customTypeError}
                  placeholder={tGlobal("template_name")}
                  name='customType'
                  onChange={handleTypeChange}
                />
              )}
              {data.preset &&
                ((isUnsynced || hasActiveChanges || hasAnyStatusProgress) &&
                currentTemplate === "custom" ? (
                  <Button primary color='blue' disabled={loading} handleClick={handleSave}>
                    {tGlobal("create")}
                  </Button>
                ) : (!data.isTemplateUpToDate && !data.isAuditUpToDate) || hasAnyStatusProgress ? (
                  <>
                    <Warning
                      dangerouslySetInnerHTML={{
                        __html: t("changes_can_not_be_implemented_warning"),
                      }}
                    />
                    <Button
                      secondary
                      color='red'
                      disabled={loading}
                      handleClick={handleDisconnectPreset}
                    >
                      {tGlobal("disconnect")}
                    </Button>
                  </>
                ) : !data.isTemplateUpToDate || hasActiveChanges ? (
                  <>
                    <Warning>
                      <Trans t={t} i18nKey='made_changes_warning' components={{ br: <br /> }} />
                    </Warning>
                    <div className={styles.row}>
                      <Button
                        secondary
                        color='red'
                        disabled={loading}
                        handleClick={handleDisconnectPreset}
                      >
                        {tGlobal("disconnect")}
                      </Button>
                      <Button
                        primary
                        color='blue'
                        disabled={loading}
                        handleClick={handleUpdatePreset}
                      >
                        {tGlobal("update")}
                      </Button>
                    </div>
                  </>
                ) : !data.isAuditUpToDate ? (
                  <>
                    <Warning
                      dangerouslySetInnerHTML={{ __html: t("template_has_been_updated_warning") }}
                    />
                    <div className={styles.row}>
                      <Button
                        secondary
                        color='red'
                        disabled={loading}
                        handleClick={handleDisconnectPreset}
                      >
                        {tGlobal("disconnect")}
                      </Button>
                      <Button
                        primary
                        color='blue'
                        disabled={loading}
                        handleClick={handlePullPreset}
                      >
                        {tGlobal("update")}
                      </Button>
                    </div>
                  </>
                ) : null)}
            </div>
          </>
        ) : (
          <>
            <span className={styles.subtitle}>{t("audit_progress")}</span>
            <div className={styles.progressBlock}>
              <span>{progress.toFixed(0)}%</span>
              <div className={styles.progressLine}>
                <div className={styles.progressFill} style={{ width: `${progress}%` }} />
              </div>
              <div className={styles.nextMeeting}>
                <div className={styles.meetingRow}>
                  <span className={styles.key}>{t("report_date")}</span>

                  <CustomDatePicker
                    placement='bottomRight'
                    onChange={handleChangeDate}
                    value={date}
                    allowClear={false}
                    customOverlay={(valueFormatted, open, openCalendar) => (
                      <span
                        onClick={openCalendar}
                        className={styles.value}
                        style={{ cursor: "pointer" }}
                      >
                        {valueFormatted}
                      </span>
                    )}
                  />
                </div>

                {date && (
                  <div className={styles.meetingRow}>
                    <span className={styles.key}>{t("time_until")}</span>
                    <span className={styles.value}>
                      {calculateExactTimeDifference(data.reportDate)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {!!statusGroupAmount && (
        <div className={styles.statusGroups}>
          {data.statusGroups
            .sort((a, b) => a.order - b.order)
            .map((item, idx) => {
              const isActiveGroup = item.id === activeGroup;

              const filteredSteps = item.steps.filter((i) => i.editModeAction !== "delete");
              const subStepsFinishedAmount = filteredSteps.filter(
                (i) => i.status === "done"
              ).length;
              const subStepsAmount = filteredSteps.length;
              const totalProgress = (subStepsFinishedAmount / subStepsAmount) * 100 || 0;
              const totalFinished = (subStepsFinishedAmount / subStepsAmount) * 100 || 0;
              const statusGroupClassName = `${styles.statusGroup} ${
                isActiveGroup ? styles.active : ""
              }`;
              const groupNumber = idx + 1;
              return (
                <div
                  className={statusGroupClassName}
                  onClick={() => handleSetActiveGroup(item.id)}
                  key={idx}
                >
                  {groupNumber < statusGroupAmount && <div className={styles.divider} />}
                  <div>
                    {totalFinished !== 100 ? (
                      <Progress
                        type='circle'
                        percent={totalProgress}
                        success={{ percent: totalFinished }}
                        strokeLinecap={"square"}
                        size={32}
                        showInfo={false}
                        strokeWidth={8}
                      />
                    ) : (
                      <StepGroupFinishedIcon />
                    )}
                  </div>
                  <div className={styles.info}>
                    <div className={styles.topRow}>
                      <span className={styles.numeration}>{groupNumber}.</span>
                      <span className={styles.name}>{STATUS_TRANSLATION[item.name]}</span>
                      <span className={styles.outOf}>
                        {subStepsFinishedAmount}/{subStepsAmount}
                      </span>
                    </div>
                    <div className={styles.botRow}>
                      {filteredSteps
                        .sort((a, b) => a.order - b.order)
                        .map((step, idx) => (
                          <div
                            className={`${styles.stepIndicator} ${styles[step.status]}`}
                            key={idx}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default StatusList;
