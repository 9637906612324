import { useEffect, useMemo, useState } from "react";
import { Card } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { getAllUsers } from "../../../../../../../api/userApi";
import { COMPANY_ROLES } from "../../../../../../../consts/companyRoles";
import {
  superadminChangeMemberRole,
  superadminChangeSignatory,
  superadminInviteCompanyMember,
  superadminRemoveCompanyMember,
} from "../../../../../../../api/superadminApi";
import MemberInfo from "../../../../../../../components/MemberRow/MemberInfo";
import InviteForm from "../../../../../../../components/InviteForm";
import MemberActions from "../../../../../../../components/MemberRow/MemberActions";

import classes from "./Team.module.scss";

const initInvite = {
  email: "",
  role: "member",
};

const Team = ({ companyData, syncDataOnSave }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [members, setMembers] = useState(companyData.members || []);
  const [invite, setInvite] = useState(initInvite);
  const [shownMenu, setShowMenu] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [error, setError] = useState();

  const memberRoles = [
    {
      label: t("member"),
      value: "member",
    },
    {
      label: t("admin"),
      value: "admin",
    },
    {
      label: t("remove_user"),
      value: "remove",
      caution: true,
    },
  ];

  const inviteRoles = [
    {
      label: t("member"),
      value: "member",
      disclaimer: "access_description",
    },
    {
      label: t("admin"),
      value: "admin",
      disclaimer: "admin_description",
      disclaimerColor: "#F17F16",
    },
  ];

  const handleGetUsers = async () => {
    const users = await getAllUsers();
    const membersEmails = members.map((member) => member.user.email);
    setUsers(users.filter((user) => !membersEmails.includes(user.email)));
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(users);
    }
  }, [users]);

  const inputData = (key, value) => {
    setInvite((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const inviteMember = async () => {
    try {
      const member = await superadminInviteCompanyMember(companyData.id, companyData.type, invite);
      const formattedMember = {
        ...member,
        role: member.role?.name || member.role,
      };
      setMembers((state) => [...state, formattedMember]);
      setUsers((state) => state.filter((user) => user.email !== invite.email));
      setInvite(initInvite);
    } catch (error) {
      setError(error?.response?.data);
      console.log(error);
    }
  };

  const changeRole = async (id, role) => {
    try {
      if (role === "remove") {
        await superadminRemoveCompanyMember(companyData.id, companyData.type, id);
        setMembers((state) => state.filter((item) => item.id !== id));
        return;
      }

      const member = await superadminChangeMemberRole(companyData.id, companyData.type, id, {
        role,
      });
      setMembers((state) => state.map((item) => (item.id === id ? { ...item, role } : item)));
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeInvite = ({ target }) => {
    const inputEmail = target.value;
    inputData("email", inputEmail);
    const filtered = users.filter(
      (user) =>
        user.email.toLowerCase().includes(inputEmail.toLowerCase()) ||
        user.firstName.toLowerCase().includes(inputEmail.toLowerCase()) ||
        user.lastName.toLowerCase().includes(inputEmail.toLowerCase())
    );
    setFilteredUsers(filtered);
    setShowMenu(true);
  };

  // TODO: ask whether auditor can choose another auditor or main admin can choose another main admin
  const roles = useMemo(() => {
    return memberRoles;
  }, []);
  const activeRole = inviteRoles.find((role) => role.value === invite.role);

  const handleChangeSignatory = async (value, memberId) => {
    try {
      const signatory = value || null;
      await superadminChangeSignatory(companyData.id, companyData.type, memberId, { signatory });
      setMembers((state) =>
        state.map((item) => (item.id === memberId ? { ...item, signatory } : item))
      );
    } catch (e) {
      console.log(e);
    }
  };

  const adminAmount = members.filter((member) => member.role === "admin").length;

  return (
    <>
      <InviteForm
        placeholder={t("invite_company")}
        invite={invite}
        inviteRoles={inviteRoles}
        inputData={inputData}
        inviteMember={inviteMember}
        onChangeInvite={onChangeInvite}
        activeRole={activeRole}
        error={error}
        setError={setError}
      />
      {members
        .sort((a, b) => b.id - a.id)
        .map((member) => {
          const { id, firstName, lastName, email, lastActive } = member.user;
          const role = member.role;
          const memberGridClasses = clsx(classes.grid, classes.memberGrid, {
            [classes.highlighted]: id === error?.userId,
          });
          const hasFlag = role === COMPANY_ROLES.MAIN_ADMIN || role === COMPANY_ROLES.AUDITOR;
          const flags = hasFlag ? [{ label: role.split("_").join(" ") }] : [];
          const signatory = member.signatory || "";
          const isAdmin = role === COMPANY_ROLES.ADMIN;
          return (
            <Card.Grid hoverable={false} className={memberGridClasses}>
              <div className={classes.memberRow}>
                <MemberInfo
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  addedAt={member.addedAt}
                  flags={flags}
                  lastActive={lastActive}
                  signatory={signatory}
                  signatoryStatus={member.user?.signatoryStatus}
                />
                <MemberActions
                  allowEdit={!isAdmin || adminAmount > 1}
                  role={role}
                  tRole={t(`role_${role}`)}
                  roles={roles}
                  member={member}
                  onChangeRole={changeRole}
                  onChangeSignatory={handleChangeSignatory}
                />
              </div>
            </Card.Grid>
          );
        })}
    </>
  );
};

export default Team;
