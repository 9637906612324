import { Popover, Space } from "antd";
import classNames from "classnames";
import { RichUtils } from "draft-js";
import React, { useMemo, useRef, useState } from "react";

import { useDocuments } from "../../../../hooks/services/useDocuments";

import {
  AttachmentIcon,
  BoldIcon,
  ColorPickerIcon,
  HeaderIcon,
  ItalicIcon,
  OrderListItemIcon,
  UnderLineIcon,
} from "../../../../AdminComponents/icons/TextEditorIcons";
import { TextEditor } from "../../../../components/ui-components";
import AttachDocuments from "../../../../components/AttachDocuments";
import DocumentOption from "../../../components/DocumentOption";

import styles from "./RichTextEditor.module.scss";

const COLORS = ["#003e47", "#E9D94A", "#ACDB30", "#FF5454"];

const CustomToolbarButton = ({ editorState, handleChange = () => {}, Icon, type, popup }) => {
  const toggleStyles = () => {
    let newEditorState;

    if (type === "attachment") {
      return handleChange(editorState);
    }

    if (type === "ordered-list-item" || type === "header-three") {
      newEditorState = RichUtils.toggleBlockType(editorState, type);
    } else {
      newEditorState = RichUtils.toggleInlineStyle(newEditorState || editorState, type);
    }

    if (newEditorState) {
      handleChange(newEditorState);
    }
  };

  return (
    <div className={styles.toolbarButtonWrapper}>
      {popup}
      <button onClick={toggleStyles}>
        <Icon className={styles.icon} />
      </button>
    </div>
  );
};

const BUTTONS = {
  "header-three": HeaderIcon,
  BOLD: BoldIcon,
  ITALIC: ItalicIcon,
  UNDERLINE: UnderLineIcon,
  "ordered-list-item": OrderListItemIcon,
  "color-picker": ColorPickerIcon,
  attachment: AttachmentIcon,
};

const PageTextEditor = ({
  onSetFieldValue,
  name,
  readOnly = false,
  value,
  editorState,
  onEditorStateChange,
  hiddenInput,
  bordered,
  onBlur,
  onFocus,
  documents,
  selectedDocuments,
  allowAttachments,
  handleAttachDocuments,
  auditId,
  isDocumentPopupActive,
  setIsDocumentPopupActive,
  disabled,
}) => {
  const ref = useRef(null);
  const [focused, setFocused] = useState(false);
  const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);
  const { createMultipleDocuments } = useDocuments();

  const handleOpenAttachDocument = () => {
    setIsDocumentPopupActive((state) => !state);
  };

  const clickHandlerByType = {
    attachment: handleOpenAttachDocument,
    ["color-picker"]: () => setIsOpenColorPicker(true),
  };

  const onAddFile = async (files) => {
    createMultipleDocuments(
      {
        documents: files,
        type: "evidence",
        auditId,
      },
      {
        onSuccess: (res) => handleAttachDocuments(res.documents),
      }
    );
  };

  const popupByType = {
    attachment: isDocumentPopupActive && (
      <AttachDocuments
        isOpen={isDocumentPopupActive}
        onAddFile={onAddFile}
        placeholder={"Search files"}
        setIsOpen={setIsDocumentPopupActive}
        options={documents}
        values={selectedDocuments}
        onChange={handleAttachDocuments}
        optionComponent={({ id, name, document }) => (
          <DocumentOption document={document} name={name} id={id} key={id} />
        )}
      />
    ),
    ["color-picker"]: (
      <Popover
        arrow={false}
        trigger='click'
        placement='top'
        open={isOpenColorPicker}
        onOpenChange={setIsOpenColorPicker}
        rootClassName={styles.colorPopover}
        content={
          <Space size={4}>
            {COLORS.map((color, index) => (
              <div
                key={index}
                style={{ background: color === COLORS[0] ? "#fff" : color }}
                className={classNames(
                  styles.colorPickerItem,
                  index === 0 && styles.colorPickerItemReset
                )}
                onClick={() => handleSetEditorColor(color)}
              />
            ))}
          </Space>
        }
      />
    ),
  };

  function handleSetEditorColor(color) {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, `color-${color}`);
    onEditorStateChange(newEditorState);
    setIsOpenColorPicker(false);
  }

  const handleFocus = (e) => {
    // return if clicked element has id #attach-documents or any parent has id #attach-documents
    if (e.target.id === "attach-documents" || e.target.closest("#attach-documents")) {
      console.log("ATTACH");
      return;
    }
    setFocused(true);
    onFocus?.(e, name);
  };

  const handleBlur = (e) => {
    console.log("BLURRR");
    setFocused(false);
    onBlur?.(e, name);
  };

  const handleSave = (e) => {
    console.log("SAVEE");
    onBlur(e, name);
  };

  const wrapperClassName = `${styles.wrapper} ${focused ? styles.focused : ""}`;
  const filteredButtons = useMemo(
    () =>
      allowAttachments
        ? Object.entries(BUTTONS)
        : Object.entries(BUTTONS).filter(([key]) => key !== "attachment"),
    [allowAttachments]
  );

  return (
    <TextEditor
      ref={ref}
      onFocus={handleFocus}
      onBlur={handleBlur}
      handleSave={onBlur && handleSave}
      disabled={disabled}
      toolbarCustomButtons={filteredButtons.map(([type, Icon], idx) => (
        <CustomToolbarButton
          Icon={Icon}
          type={type}
          idx={idx}
          handleChange={clickHandlerByType[type] || onEditorStateChange}
          popup={popupByType[type]}
        />
      ))}
      name={name}
      readOnly={readOnly}
      onSetFieldValue={onSetFieldValue}
      toolbar={{
        link: {
          inDropdown: false,
          defaultTargetOption: "_blank",
          options: ["link", "unlink"],
        },
        options: [],
      }}
      value={value}
      editorState={editorState}
      // TODO: GET /audit/:id/team-members - returns all team members for mention logic
      // mention={{
      //   separator: ' ',
      //   trigger: '@',
      //   suggestions: [
      //     { text: 'APPLE', value: 'apple', url: 'apple' },
      //     { text: 'BANANA', value: 'banana', url: 'banana' },
      //     { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
      //     { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
      //   ],
      // }}
      onEditorStateChange={onEditorStateChange}
      editorClassName={styles.editor}
      toolbarClassName={styles.toolbar}
      wrapperClassName={wrapperClassName}
      hiddenInput={hiddenInput}
      bordered={bordered}
    />
  );
};

export default PageTextEditor;
