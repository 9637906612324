import React, { forwardRef, memo, useCallback } from "react";
import classNames from "classnames";
import { Editor, EditorProps, EditorState } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, RichUtils } from "draft-js";
// @ts-ignore
import draftToHtml from "draftjs-to-html";

import debounce from "../../../utils/debounde";

import styles from "./TextEditor.module.scss";

const cx = classNames.bind(styles);

type ToolbarOptions = {
  options: string[];
  inline?: {
    options: string[];
  };
  list?: {
    options: string[];
  };
  link?: {
    showOpenOptionOnHover: boolean;
  };
};

interface TextEditorProps extends EditorProps {
  name: string;
  label: string;
  value: string;
  required?: boolean;
  readOnly?: boolean;
  bordered?: boolean;
  hiddenInput?: boolean;
  editorState: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
  toolbar?: ToolbarOptions;
  disabled?: boolean;
  onBlur?: any;
  handleSave?: any;
}

const TextEditor = forwardRef<HTMLDivElement, TextEditorProps>(
  (
    {
      label,
      name,
      value,
      required,
      readOnly,
      editorState,
      onEditorStateChange,
      hiddenInput,
      bordered,
      toolbar = {
        options: ["inline", "list", "link", "blockType"],
        inline: {
          options: ["bold", "italic", "underline"],
        },
        list: { options: ["unordered", "ordered"] },
        link: { showOpenOptionOnHover: false },
      },
      disabled,
      handleSave,
      ...props
    },
    ref
  ) => {
    const classNames = cx(styles.root, {
      [styles.readOnly]: readOnly,
      [styles.hiddenInput]: hiddenInput,
      [styles.bordered]: bordered,
      [styles.disabled]: disabled,
    });

    const debouncedFetchData = useCallback(
      debounce(async (value) => {
        const html = draftToHtml(convertToRaw(value.getCurrentContent()));
        const e = {
          target: {
            name,
            value: html,
          },
        };
        handleSave(e, name);
      }, 250),
      []
    );

    const handleEditorStateChange = (editorState: EditorState) => {
      onEditorStateChange(editorState);
      if (handleSave) {
        debouncedFetchData(editorState);
      }
    };

    const handleKeyCommandEn = (command: string) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        handleEditorStateChange(newState);
        return "handled";
      }
      return "not-handled";
    };

    return (
      <div className={classNames} ref={ref}>
        <Editor
          toolbar={toolbar}
          editorState={editorState}
          readOnly={readOnly}
          handleKeyCommand={handleKeyCommandEn}
          onEditorStateChange={handleEditorStateChange}
          {...props}
        />
      </div>
    );
  }
);

export default memo(TextEditor);
