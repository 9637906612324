import { useState } from "react";
import { Col, Row, Card } from "antd";
import { useTranslation } from "react-i18next";
import FormGroup from "@mui/material/FormGroup";

import { sendData } from "../../../../../api/userApi";

import CustomSwitch from "../../../../../components/ui-components/Switch/Switch";
import CardActions from "../../../../../Admin/pages/components/CardActions";
import { Label } from "../../../../../components/ui-components";
import { getBooleans } from "../../../../../utils/getBooleans";

const AccountSettingsNotifications = ({ userData, syncDataOnSave }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [answers, setAnswers] = useState(() => {
    return getBooleans(userData);
  });

  const isEdited = Object.entries(answers).some(([key, value]) => userData[key] !== value);

  const fromGroup = [
    { id: "statusUpdateNotification", name: t("account_notifications_new_status_update") },
    { id: "documentUploadNotification", name: t("account_notifications_new_document_upload") },
    { id: "commentAddedNotification", name: t("account_notifications_new_comments_added") },
    { id: "commentMentionNotification", name: t("account_notifications_new_comment_mentioned") },
    { id: "requestCreatedNotification", name: t("account_notifications_new_requests_created") },
  ];

  const discardData = () => {
    setAnswers(userData);
  };

  const onSubmit = () => {
    sendData(answers)
      .then(() => {
        const checkboxes = getBooleans(answers);
        syncDataOnSave(checkboxes);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <Card.Grid hoverable={false}>
        <Row gutter={24}>
          <Col span={8}>
            <Label type='primary'>{t("all_notifications")}</Label>
          </Col>
          <Col span={16}>
            <FormGroup>
              <CustomSwitch
                checked={answers.showNotifications}
                onChange={() => {
                  setAnswers({
                    ...answers,
                    showNotifications: !answers.showNotifications,
                  });
                }}
                label={t("enable_notifications")}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={8}>
            <Label type='primary'>{t("email_notifications")}</Label>
          </Col>
          <Col span={16}>
            {fromGroup.map((item, index) => (
              <div key={index}>
                <FormGroup>
                  <CustomSwitch
                    checked={answers[item.id]}
                    onChange={() => {
                      setAnswers({
                        ...answers,
                        [item.id]: !answers[item.id],
                      });
                    }}
                    label={item.name}
                  />
                </FormGroup>
                <br />
              </div>
            ))}
          </Col>
        </Row>
      </Card.Grid>
      {isEdited && <CardActions onSubmit={onSubmit} onCancel={discardData} text={t("change")} />}
    </>
  );
};

export default AccountSettingsNotifications;
