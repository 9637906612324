import Assertion from "../../../../../../components/ui-components/Assertion/Assertion";

import { CheckmarkGreenIcon, ChevronRight, PlusCircleIcon } from "../../../../../../assets/icons";

import styles from "../StatusView.module.scss";

const SubtaskList = ({ subtasks = [], allowCreate, onClick }) => {
  return (
    <div className={styles.subtasks}>
      {subtasks
        .filter((i) => i.editModeAction !== "delete")
        .sort((a, b) => (a.order !== b.order ? a.order - b.order : a.id - b.id))
        .map((i) => (
          <div className={styles[i.status]} onClick={() => onClick(i)}>
            <div className={styles.subtaskInfo}>
              {i.status === "done" ? <CheckmarkGreenIcon /> : <div className={styles.status} />}
              <span className={styles.type}>{i.type.split("_").join(" ")}</span>
              <span className={styles.name}>{i.name}</span>
            </div>
            <div className={styles.subtaskActions}>
              <div className={styles.assertions}>
                {i.assertions
                  ?.sort((a, b) => a.id - b.id)
                  .map((i) => (
                    <Assertion id={i.id}>{i.title}</Assertion>
                  ))}
              </div>
              <ChevronRight />
            </div>
          </div>
        ))}
      {allowCreate && (
        <div className={styles.createNew} onClick={() => onClick(true)}>
          <PlusCircleIcon className={styles.addNew} />
        </div>
      )}
    </div>
  );
};

export default SubtaskList;
