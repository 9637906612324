import { useState, useEffect } from "react";
import { Route, useLocation } from "react-router-dom";

import { Navigation } from "../Routers/Navigation";
import DashboardAside from "./components/DashboardAside";
import Chat from "../components/Chat";
import SyncAuditData from "./components/SyncAuditData";
import HeaderAdmin from "./components/HeaderAdmin";

import chatAll from "../assets/admin/chatAll.svg";

import "./styles/dashboard_main.css";

const DashboardMain = (props) => {
  const { pathname } = useLocation();
  const [openChat, setOpenChat] = useState(false);

  const hideAdminHeader = /\/dashboard\/\d+\/documents\/\d+/.test(pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <SyncAuditData>
      <div className='wrapper_admin_app'>
        <DashboardAside pathname={pathname} />
        <div className='wrapper_admin_app__content'>
          {hideAdminHeader || <HeaderAdmin dashboard backTo='/audits' />}
          {Navigation.adminDashboardMain.map((route) => (
            <Route
              path={route.path}
              exact={route.exact ?? true}
              {...props}
              component={route.main}
              key={route.path}
            />
          ))}
        </div>
        {openChat ? (
          <div className='chat-block'>
            <Chat close={(e) => setOpenChat(e)} />
          </div>
        ) : (
          <div
            className='chatSvg'
            onClick={() => {
              setOpenChat(true);
            }}
          >
            <img src={chatAll} alt='Chat' />
          </div>
        )}
      </div>
    </SyncAuditData>
  );
};

export default DashboardMain;
