import { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";

import { Navigation } from "../Routers/Navigation";

import "./styles/dashboard_main.css";

const AdminPlatform = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {Navigation.adminPlatform.map((route) => (
        <Route
          path={route.path}
          exact={route.exact ?? true}
          component={route.main}
          key={route.path}
        />
      ))}
    </>
  );
};

export default AdminPlatform;
