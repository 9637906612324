import API from "./API";
import { assertionColors } from "../consts/assertionColors";

export const getAssertions = async (auditId) => {
  try {
    const response = await API().get(`/audit/${auditId}/assertions`);
    return response.data.assertions;
  } catch (e) {
    return [];
  }
};

export const createAssertion = async (auditId, title) => {
  const response = await API().post(`/audit/${auditId}/assertions`, { title });
  return response.data?.assertion;
};
