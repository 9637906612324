import React from "react";

const ItalicIcon = ({ ...restAttributes }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...restAttributes}
  >
    <path
      d='M9.99935 13.3334H4.66602V12H6.61735L8.02802 4.00002H5.99935V2.66669H11.3327V4.00002H9.38135L7.97068 12H9.99935V13.3334Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
  </svg>
);

export default ItalicIcon;
