import { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";

import { oneAudit, allAudits } from "../../../api/auditApi";
import { removeMeetingData, removeRequestData } from "../../../store/global/actions";
import { setUserPermissionsRole } from "../../../store/user/actions";
import { userPermissionsRole } from "../../../store/user/api";

import { Navigation } from "../../../Routers/Navigation";
import OutsideClickHandler from "../../../consts/detectOutSideClick";
import { Button } from "../../../components/ui-components";

import searchSVG from "../../../assets/admin/search.svg";
import { ChevronRightIcon, Logo, MinimizeIcon } from "../../../assets/icons";

function DashboardAside({ pathname }) {
  const { id: idAudit } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "audits" });

  const lastRouteChange = useRef(null);
  const userRole = useSelector((state) => state.user.user_role);

  const [subMenu, setSubMenu] = useState(false);
  const [subMenuSvg, setSubMenuSvg] = useState(true);
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState("");
  const [audits, setAudits] = useState(false);
  const [isMinimised, setIsMinimised] = useState(false);

  const { role, isAuditor, isCompanyAdmin } = userRole || {};
  const activePath = pathname.split("/")[3];

  const onSubmit = (id) => {
    dispatch(removeRequestData());
    dispatch(removeMeetingData());
    //    history.push(`/dashboard/${id}/${activePath}`);
    // currently state persists on page change, so we need to reload the page
    window.location.href = `/dashboard/${id}/${activePath}`;
    setSubMenu(false);
  };

  useEffect(() => {
    // get local storage value
    const isMinimised = localStorage.getItem("minimised");
    if (isMinimised) {
      setIsMinimised(true);
      document.body.classList.add("minimised");
    }
    oneAudit(idAudit)
      .then((res) => {
        setUserData({
          name: res.audit.company.name,
          revision: res.audit.name,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    allAudits()
      .then((res) => {
        setAudits(res.companies);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [idAudit]);

  const fetchPermissions = () => {
    userPermissionsRole(idAudit).then((res) => {
      dispatch(setUserPermissionsRole(res.teamMember));
    });
  };

  useEffect(() => {
    const currentTime = Date.now();
    const threeMinutes = 60 & 1000; // 1 minute in milliseconds

    if (
      (lastRouteChange.current && currentTime - lastRouteChange.current >= threeMinutes) ||
      !lastRouteChange?.current
    ) {
      fetchPermissions();
      lastRouteChange.current = currentTime;
    }
  }, [pathname]);

  const toggleMinimised = () => {
    setIsMinimised(!isMinimised);
    // update body class and local storage
    if (isMinimised) {
      document.body.classList.remove("minimised");
      localStorage.removeItem("minimised");
    } else {
      document.body.classList.add("minimised");
      localStorage.setItem("minimised", "true");
    }
  };

  const changeAudit = () => {
    window.location.reload();
  };

  return (
    <>
      <div className={`${subMenu ? "sub_menu_wrapper_active" : ""} sub_menu_wrapper`}>
        {subMenu && (
          <OutsideClickHandler
            callback={() => {
              setSubMenu(false);
              setSubMenuSvg(false);
              setTimeout(() => {
                setSubMenuSvg(true);
              }, 250);
            }}
          >
            <div className={"sub_menu"}>
              <span className='title' style={{ marginLeft: "24px" }}>
                {t("my_audits")}
              </span>
              <div className='input-block'>
                <img src={searchSVG} alt='search' />
                <input
                  placeholder={t("search_audits")}
                  onChange={({ target }) => {
                    setSearch(target.value);
                  }}
                />
              </div>
              {audits.map(
                (i, index) =>
                  (i.audits.filter((el) => el.name.toLowerCase().includes(search.toLowerCase()))
                    .length > 0 ||
                    !search) && (
                    <div className='wrapper_section' key={index}>
                      <div className={"title"}>{i.name}</div>
                      <div className={"list"}>
                        {i.audits.length > 0 ? (
                          i.audits
                            .filter((el) => el.name.toLowerCase().includes(search.toLowerCase()))
                            .map((item, key) => (
                              <div
                                onClick={() => onSubmit(item.id)}
                                className={`${
                                  item.id === parseInt(idAudit) ? "list__every_active" : ""
                                } list__every pointer`}
                                key={key}
                              >
                                <div className={"navigation_point"} />
                                <span
                                  className={`${
                                    item.id === parseInt(idAudit) ? "title_active" : "title"
                                  }`}
                                >
                                  {item.name}
                                </span>
                                <span>
                                  <CircularProgressbar
                                    strokeWidth={25}
                                    className='progressBar_navigation'
                                    value={item.progress}
                                  />
                                </span>
                              </div>
                            ))
                        ) : (
                          <div className='list__every'>
                            <span className={"title"}>No audits</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )
              )}
            </div>
          </OutsideClickHandler>
        )}
      </div>
      <div className='old_school_navigation'>
        <div className='logo-row'>
          <Link to={"/audits"}>
            <Logo />
          </Link>
          <Button handleClick={toggleMinimised} color={"bright"} tertiary icon={<MinimizeIcon />} />
        </div>
        <div
          className={"limited_revision"}
          onClick={() => {
            if (subMenuSvg) {
              setSubMenu(!subMenu);
            }
          }}
        >
          <div className={"limited_revision__info"}>
            <span className={"title"}>{userData.name}</span>
            <span className={"description"}>{userData.revision}</span>
          </div>
          <Button tertiary color={"bright"} icon={<ChevronRightIcon isActive={subMenu} />} />
        </div>
        <div className={"old_school_navigation__links"}>
          {Navigation.adminDashboardMain
            .filter((i) => !i.hide)
            .map(
              (route, index) =>
                index < 6 &&
                role &&
                (isAuditor ||
                  isCompanyAdmin ||
                  role[route.disabled] ||
                  route.param === "settings") && (
                  <Link
                    to={
                      idAudit === ":id"
                        ? "/audits"
                        : `/dashboard/${idAudit}/${route.param}${
                            ["requests", "meetings"].includes(route.param) ? "/new" : ""
                          }`
                    }
                    className={`${activePath === route.param ? "active" : ""} ${
                      route.param === "settings" ? "settings-block" : ""
                    } every`}
                    key={route.param}
                  >
                    {route.icon}
                    {route.name}
                  </Link>
                )
            )}
        </div>
      </div>
    </>
  );
}

export default DashboardAside;
