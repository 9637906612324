import { Avatar, Button, Dropdown, Typography } from "antd";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { downloadDocument, restoreDeletedDocument } from "../../../../../api/documentApi";
import { STATUS_COLORS } from "../../../../../consts/enums";
import { useDocuments } from "../../../../../hooks/services/useDocuments";
import { downloadFileFromURL } from "../../../../../utils/downloadFileFromURL";
import { getHumanFileSize } from "../../../../../utils/files";

import { Label, Tag } from "../../../../../components/ui-components";
import DocumentSharedWith from "../DocumentSharedWith";
import FileExtension from "../../../../../Admin/components/FileExtension";
import DocumentRowBacklinks from "../DocumentRowBacklinks";
import EditableDocumentName from "../../../../../components/EditableDocumentName";
import StyledTooltip from "../../../../../components/ui-components/StyledTooltip";
import DeleteModal from "../../../../../components/DeleteModal";
import CustomAvatar from "../../../../../components/CustomAvatar";
import ReplaceDocumentPopover from "../ReplaceDocumentPopover";
import DocumentRowUsersTooltip from "../DocumentRowUsersTooltip";
import UserBadge from "../../../../../components/UserBadge";

import { ReactComponent as DownloadIcon } from "../../../../../assets/icons/download.svg";
import { ReactComponent as PencilIcon } from "../../../../../assets/icons/pencil.svg";
import { ReactComponent as RepeatIcon } from "../../../../../assets/icons/repeat.svg";
import { ReactComponent as RestoreIcon } from "../../../../../assets/icons/restore.svg";
import { ReactComponent as TrashIcon } from "../../../../../assets/icons/trash.svg";
import { ReactComponent as TrashSmIcon } from "../../../../../assets/icons/trash-sm.svg";
import { ThreeDotsIcon } from "../../../../../assets/icons";

import styles from "./DocumentRow.module.scss";

const DocumentRow = ({
  id,
  access,
  name,
  document,
  document_size,
  type,
  statusMentions = [],
  accessibleBy,
  addedBy,
  auditId,
  requests = [],
  meetings = [],
  quoteRequestId,
  dependentSteps = [],
  status,
  addedAt,
  signers,
  onEdit,
  quoteRequest,
  showDeleted,
  ...rest
}) => {
  const { id: idAudit } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isReplacePopupOpen, setIsReplacePopupOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeletePopup] = useState(false);
  const { deleteDocument } = useDocuments();

  const backlinkAmount = useMemo(() => {
    let count = dependentSteps.length + statusMentions.length + requests.length + meetings.length;
    if (quoteRequestId) {
      count++;
    }
    return count;
  }, [requests, meetings, quoteRequestId, dependentSteps, statusMentions]);
  const date = dayjs(addedAt).format("D MMMM YYYY, h:mm A");
  const owners = [
    addedBy,
    ...(access
      ? access.filter((i) => i.role === "owner" && i.user.id !== addedBy.id).map((i) => i.user)
      : []),
  ];

  const handleDeleteDocument = () => {
    if (backlinkAmount > 0) return;
    deleteDocument({ idAudit, documentId: id });
    setConfirmDeletePopup(false);
  };

  const handleReplaceDocument = () => {
    setIsMenuOpen(false);
    setIsReplacePopupOpen(true);
  };

  const handleDownload = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const res = await downloadDocument(auditId, id);
      const result = await downloadFileFromURL(res, name || document);

      if (!result) window.open(res, "_blank");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenActionMenu = () => {
    setIsMenuOpen(true);
    setIsReplacePopupOpen(false);
  };

  const handleRestore = async () => {
    await restoreDeletedDocument(auditId, id);
  };

  const actionItems = [
    {
      key: "0",
      icon: <PencilIcon />,
      onClick: onEdit,
      label: t("edit_document"),
    },
    {
      key: "1",
      icon: <RepeatIcon />,
      onClick: handleReplaceDocument,
      label: t("replace_document"),
    },
    {
      key: "2",
      danger: true,
      icon: <TrashSmIcon width={16} height={16} />,
      disabled: backlinkAmount > 0,
      onClick: () => setConfirmDeletePopup(true),
      label: (
        <StyledTooltip
          theme='light'
          placement='left'
          title={backlinkAmount > 0 ? t("delete_document_tooltip") : ""}
        >
          <span className={styles.actionItemText}>{t("delete_document")}</span>
        </StyledTooltip>
      ),
    },
  ];

  return (
    <div {...rest} className={styles.wrapper}>
      <Link to={`/dashboard/${auditId}/documents/${id}`} className={styles.root}>
        <div className={styles.row}>
          <FileExtension document={document} />
          <div className={styles.column}>
            <div className={styles.documentName}>
              <EditableDocumentName id={id} name={name} />
            </div>
            <div className={styles.row}>
              {document_size && (
                <span className={styles.fileSize}>{getHumanFileSize(document_size)}</span>
              )}
              {backlinkAmount > 0 && (
                <DocumentRowBacklinks
                  requests={requests}
                  meetings={meetings}
                  statusMentions={statusMentions}
                  dependentSteps={dependentSteps}
                  quoteRequest={quoteRequest}
                  total={backlinkAmount}
                />
              )}
            </div>
          </div>
        </div>
        <DocumentRowUsersTooltip title={t("owners")} users={owners}>
          <Button className={styles.tooltipButton}>
            <Avatar.Group>
              {owners?.map((user) => (
                <CustomAvatar width={24} height={24} user={user} key={user.id} />
              ))}
            </Avatar.Group>
          </Button>
        </DocumentRowUsersTooltip>
        <DocumentSharedWith access={access} type={type} accessibleBy={accessibleBy} />
        <StyledTooltip
          placement='bottomLeft'
          title={
            signers?.length > 0 && (
              <>
                <Label className={styles.tooltipTitle}>{t("require_signatures")}</Label>
                {signers.map((item) => (
                  <UserBadge
                    key={item?.id}
                    signStatus={item.status}
                    user={item?.auditorMember?.member?.user || item?.teamMember?.user}
                  />
                ))}
              </>
            )
          }
          theme='light'
        >
          <button className={styles.tooltipButton}>
            {type === "legal" && signers?.length > 0 && (
              <Tag color={STATUS_COLORS[status]}>{t(status)}</Tag>
            )}
          </button>
        </StyledTooltip>
        <span className={styles.date}>{date}</span>
      </Link>

      <div className={styles.actions}>
        {showDeleted ? (
          <>
            <StyledTooltip placement='bottom' theme='light' title={tGlobal("restore")}>
              <Button type='text' icon={<RestoreIcon />} onClick={handleRestore} />
            </StyledTooltip>
            <StyledTooltip
              placement='bottomRight'
              theme='light'
              title={
                <Typography.Text type='danger'>{tGlobal("delete_permanently")}</Typography.Text>
              }
            >
              <Button
                danger
                type='text'
                icon={<TrashIcon />}
                onClick={() => setConfirmDeletePopup(true)}
              />
            </StyledTooltip>
          </>
        ) : (
          <>
            <Button type='text' icon={<DownloadIcon />} onClick={handleDownload} />

            <Dropdown
              onOpenChange={setIsMenuOpen}
              open={isMenuOpen}
              menu={{ items: actionItems }}
              trigger={["click"]}
            >
              <Button type='text' icon={<ThreeDotsIcon />} onClick={handleOpenActionMenu} />
            </Dropdown>
            <ReplaceDocumentPopover
              documentId={id}
              open={isReplacePopupOpen}
              onOpenChange={setIsReplacePopupOpen}
              onBack={handleOpenActionMenu}
            >
              <span />
            </ReplaceDocumentPopover>
          </>
        )}
      </div>
      <DeleteModal
        open={confirmDeleteOpen}
        onCancel={() => setConfirmDeletePopup(false)}
        onDelete={handleDeleteDocument}
        content={
          <Trans
            t={t}
            i18nKey='delete_document_confirmation_message'
            values={{ documentName: name }}
            components={{ b: <b /> }}
          />
        }
      />
    </div>
  );
};

export default DocumentRow;
