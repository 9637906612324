import React from "react";

const UnderLineIcon = ({ ...restAttributes }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...restAttributes}
  >
    <path
      d='M5.33268 2V8C5.33268 8.70724 5.61363 9.38552 6.11373 9.88562C6.61383 10.3857 7.2921 10.6667 7.99935 10.6667C8.70659 10.6667 9.38487 10.3857 9.88497 9.88562C10.3851 9.38552 10.666 8.70724 10.666 8V2H11.9993V8C11.9993 9.06087 11.5779 10.0783 10.8278 10.8284C10.0776 11.5786 9.06021 12 7.99935 12C6.93848 12 5.92107 11.5786 5.17092 10.8284C4.42078 10.0783 3.99935 9.06087 3.99935 8V2H5.33268ZM2.66602 13.3333H13.3327V14.6667H2.66602V13.3333Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
  </svg>
);

export default UnderLineIcon;
