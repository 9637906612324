import { EditorState, CompositeDecorator, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import React from "react";

const LinkComponent = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a href={url} target='_blank' rel='noopener noreferrer'>
      {children}
    </a>
  );
};
export const prepareDraft = (value) => {
  const draft = htmlToDraft(value);
  let contentState = ContentState.createFromBlockArray(draft.contentBlocks);

  // Iterate through all blocks and update entities with type "LINK"
  contentState.getBlockMap().forEach((block) => {
    block.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (entityKey)
          console.log(
            contentState.getEntity(entityKey),
            contentState.getEntity(entityKey).getType()
          );
        return entityKey !== null && contentState.getEntity(entityKey).getType() !== "LINK";
      },
      (start, end) => {
        const entityKey = block.getEntityAt(start);
        const entity = contentState.getEntity(entityKey);
        if (entity && entity.getType() !== "LINK" && entity.getData().url) {
          contentState = contentState.mergeEntityData(entityKey, { type: "LINK" });
        }
      }
    );
  });

  const linkDecorator = new CompositeDecorator([
    {
      strategy: (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
        }, callback);
      },
      component: LinkComponent,
    },
  ]);

  return EditorState.createWithContent(contentState, linkDecorator);
};
