import axios from "axios";
import helper from "../consts/helper";
import { saveLastVisitedPage } from "../utils/saveLastVisitedPage";
// import {clearCookies} from "../Constants/clearCookies";

function API(token = null) {
  const baseURL = process.env.REACT_APP_API_URL;
  let headers = {
    Accept: "application/json",
  };

  if (token ?? localStorage.getItem(helper.JWT_TOKEN)) {
    headers.Authorization = "Bearer " + (token ?? localStorage.getItem(helper.JWT_TOKEN));
  }

  let api = axios.create({
    baseURL: baseURL,
    timeout: 180000,
    headers: headers,
  });

  api.interceptors.request.use((request) => requestHandler(request));

  api.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
  );

  const successHandler = (response) => {
    if (response.headers["x-new-token"]) {
      localStorage.setItem(helper.JWT_TOKEN, response.headers["x-new-token"]);
    }
    return response;
  };
  const requestHandler = (request) => request;

  const errorHandler = (error) => {
    if (error?.response?.status === 401) {
      saveLastVisitedPage();
      userLogout();
      return Promise.reject({ ...error });
    } else if (error?.response?.status === 404) {
      console.log(error);
      // debugger
      // window.location = "/404"
      return Promise.reject({ ...error });
    } else {
      console.log(error);
      // debugger
      return Promise.reject({ ...error });
    }
  };
  return api;
}

export function userLogout(isUser = null) {
  if (isUser) {
    API()
      .post("/user/logout")
      .then(() => forceLogout())
      .catch(() => forceLogout());
  } else {
    forceLogout();
  }
}

export function forceLogout() {
  localStorage.removeItem(helper.JWT_TOKEN);
  localStorage.removeItem(helper.USER_DATA);
  localStorage.removeItem(helper.BEXIO_REDIRECT_URL);
  localStorage.removeItem(helper.BEXIO_REQUEST_DATA);
  localStorage.removeItem(helper.BEXIO_TOKEN);

  sessionStorage.clear();

  window.location = "/login";
}

export default API;
