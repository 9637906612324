import React from "react";

const HeaderIcon = ({ ...restAttributes }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...restAttributes}
  >
    <path
      d='M11.334 7.33335V2.66669H12.6673V14H11.334V8.66669H4.66732V14H3.33398V2.66669H4.66732V7.33335H11.334Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
  </svg>
);

export default HeaderIcon;
