import { AxiosRequestConfig } from "axios";
import API from "../api/API";
import { AddAuditPayloadData, AuditsResponseType } from "../types/audit";
import ApiNew from "./apiNew";

export const fetchAudits = async (config?: AxiosRequestConfig): Promise<AuditsResponseType> => {
  try {
    const response = await API().get("/audit/", config);
    return response.data;
  } catch (error) {
    console.error("Error creating voucher", error);
    throw error;
  }
};

export const createAudit = async (data: AddAuditPayloadData): Promise<AddAuditPayloadData> => {
  try {
    const response = await ApiNew().post("/audit/create", data);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error creating voucher", error);
    throw error;
  }
};
