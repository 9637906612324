import { Drawer } from "antd";
import { useEffect, useState } from "react";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";

import useQueryParams from "../../../../../../hooks/useQueryParams";

import { Button, Input, Tag } from "../../../../../../components/ui-components";
import { PageTextEditor } from "../../../../components";
import FormPicker from "../../../../components/FormPicker/FormPicker";
import Switch from "../../../../../../components/ui-components/Switch/Switch";
import AddAssertion from "../../../../../../components/AddAssertion";
import CreateEditSubtask from "../CreateEditSubtask";
import SubtaskList from "../SubtaskList";
import Assertion from "../../../../../../components/ui-components/Assertion/Assertion";

import { PlusDarkIcon } from "../../../../../../assets/icons";

import styles from "../StatusView.module.scss";
import { OWNERSHIP_OPTIONS, TASK_TYPE_BY_OWNERSHIP } from "../../../Status.constants";

const initState = {
  id: (+new Date()).toString(),
  ownership: "client",
  name: "",
  type: "",
  description: "",
  subtasks: [],
  assertions: [],
};

const requiredFields = ["name", "type", "ownership"];

const CreateEditStep = ({
  createStepIdx,
  setCreateStepIdx,
  idAudit,
  finishCreate,
  statusGroup,
  stepData,
  setStepData,
  finishUpdate,
  finishDelete,
  noAnimation,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const [data, setData] = useState(initState);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [errors, setErrors] = useState({});
  const [addAssertionPopup, setAddAssertionPopup] = useState(false);
  const [openCreateSubtask, setOpenCreateSubtask] = useState(false);

  const { removeQueryParams } = useQueryParams();

  const handleOpenCreateSubtask = (subtask) => {
    setOpenCreateSubtask(subtask);
  };

  useEffect(() => {
    if (stepData) {
      setData(stepData);
      const draft = htmlToDraft(stepData.description);
      const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [stepData]);

  const onEditorStateChange = (editorState) => {
    const rawValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setData((prev) => ({
      ...prev,
      description: rawValue,
    }));
    setEditorState(editorState);
  };

  const handleChangePicker = (name, value) => {
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: false,
    }));

    if (name === "ownership") {
      setData((prev) => ({
        ...prev,
        type: "",
        optional: false,
        helpText: "",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  const handleCreateStep = async () => {
    const errors = Object.entries(data).reduce((acc, [key, value]) => {
      if (requiredFields.includes(key) && !value) {
        acc[key] = true;
      }
      return acc;
    }, {});

    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    if (isEdit) {
      // const res = await updateStep(idAudit, stepData.id, {
      //   ...data,
      //   statusGroupId: statusGroup,
      // });
      // console.log(res);
      // if (!res.step) return;
      const newStep = {
        ...data,
        statusGroupId: statusGroup,
      };

      if (!stepData.editModeAction) {
        newStep.editModeAction = "update";
      }
      finishUpdate(newStep);
    } else {
      // const res = await createStep(idAudit, {
      //   ...data,
      //   order: createStepIdx + 1,
      //   statusGroupId: statusGroup,
      // });
      // console.log(res);
      //
      // if (!res.step) return;
      const newStep = {
        ...data,
        order: createStepIdx + 1,
        statusGroupId: statusGroup,
        editModeAction: "create",
      };

      finishCreate(newStep);
    }

    handleCloseDrawer();
  };

  const handleCloseDrawer = () => {
    setCreateStepIdx(null);
    setData(initState);
    setStepData(null);
    setEditorState(EditorState.createEmpty());
    setErrors({});
    removeQueryParams(["createStep", "openedStep"]);
  };

  const handleDeleteStep = async () => {
    // if (!stepData?.id) return;
    // const res = await deleteStep(idAudit, stepData.id);
    // console.log(res);
    if (isEdit) {
      finishDelete(stepData);
    }
    handleCloseDrawer();
  };

  const closeAddAssertionPopup = () => {
    setAddAssertionPopup(false);
  };

  const openAddAssertionPopup = () => {
    setAddAssertionPopup(true);
  };

  const handleSelectAssertion = (assertion) => {
    setData({
      ...data,
      assertions: [...data.assertions, assertion],
    });
  };

  const finishSubtaskCreate = (newStep) => {
    const id = (+new Date()).toString();
    const order = data.subtasks.length + 1;
    const newSteps = [...data.subtasks, { ...newStep, id, order }];
    setData({
      ...data,
      subtasks: newSteps,
    });
    setOpenCreateSubtask(false);
  };

  const finishSubtaskUpdate = (updatedStep) => {
    const newSteps = data.subtasks.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    setData({
      ...data,
      subtasks: newSteps,
    });
  };

  const finishSubtaskDelete = (step) => {
    const newSteps =
      step.editModeAction === "create"
        ? data.subtasks.filter((i) => i.id !== step.id)
        : data.subtasks.map((i) => (i.id === step.id ? { ...i, editModeAction: "delete" } : i));
    setData({
      ...data,
      subtasks: newSteps,
    });
  };

  const removeAssertion = (id) => {
    setData((state) => ({
      ...state,
      assertions: state.assertions.filter((i) => i.id !== id),
    }));
  };

  const isEdit = !!stepData;
  return (
    <>
      <CreateEditSubtask
        idAudit={idAudit}
        finishCreate={finishSubtaskCreate}
        finishUpdate={finishSubtaskUpdate}
        finishDelete={finishSubtaskDelete}
        parentStep={stepData || data}
        stepData={openCreateSubtask}
        setStepData={handleOpenCreateSubtask}
        noAnimation={noAnimation}
      />
      <Drawer
        open={!!createStepIdx || createStepIdx === 0 || isEdit}
        rootClassName={`${
          noAnimation ? styles.noAnimation : ""
        } edit-step-drawer create-step-drawer`}
        onClose={handleCloseDrawer}
        closable={false}
      >
        <div className={`${styles.drawerContent} ${styles.spacing}`}>
          <div className={styles.drawerHeader}>
            <div className={styles.drawerHead}>
              <span className={styles.drawerTitle}>{isEdit ? t("edit_task") : t("new_task")}</span>
              <div className={styles.actions}>
                <Button handleClick={handleCloseDrawer} color={"red"} secondary>
                  {tGlobal("discard")}
                </Button>
                <Button handleClick={handleCreateStep} color={"green"} primary>
                  {isEdit ? tGlobal("update") : tGlobal("create")}
                </Button>
              </div>
            </div>
            <div className={styles.drawerBodyGrid}>
              <FormPicker
                options={OWNERSHIP_OPTIONS}
                error={errors.ownership}
                selected={data.ownership}
                name='ownership'
                onSelect={handleChangePicker}
              />
              <Input
                value={data.name}
                error={errors.name}
                name='name'
                placeholder={t("task_title")}
                onChange={handleChange}
              />
              {data.ownership === "auditor" && (
                <div className={styles.assertionRow}>
                  {data?.assertions
                    ?.sort((a, b) => a.id - b.id)
                    .map((assertion, index) => (
                      <Assertion onClose={removeAssertion} id={assertion.id} key={index}>
                        {assertion.title}
                      </Assertion>
                    ))}
                  <Tag color='gray' onClick={openAddAssertionPopup}>
                    <PlusDarkIcon style={{ marginRight: 4 }} /> {t("add_assertion")}
                  </Tag>
                  <AddAssertion
                    isOpen={addAssertionPopup}
                    onClose={closeAddAssertionPopup}
                    values={data.assertions}
                    handleSelect={handleSelectAssertion}
                  />
                </div>
              )}
              <FormPicker
                name='type'
                label={t("task_type")}
                options={TASK_TYPE_BY_OWNERSHIP[data.ownership]}
                error={errors.type}
                selected={data.type}
                onSelect={handleChangePicker}
              />
              <PageTextEditor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                bordered={true}
                readOnly={false}
                label={tGlobal("description")}
                value={data.value}
                name='description'
                hiddenInput={false}
              />
              {data.ownership === "auditor" && (
                <div className={styles.field}>
                  <label>{t("optional_task")}</label>
                  <FormControlLabel
                    style={{ marginLeft: "4px" }}
                    control={
                      <Switch
                        checked={data.optional}
                        onChange={() => {
                          handleChangePicker("optional", !data.optional);
                        }}
                      />
                    }
                    label={t("optional_task_description")}
                  />
                </div>
              )}
              {data.type === "simple" && (
                <div className={styles.field}>
                  <label>{t("help_text_label")}</label>
                  <Input
                    name='helpText'
                    value={data.helpText}
                    onChange={handleChange}
                    placeholder={t("help_text_placeholder")}
                  />
                </div>
              )}
              {data.ownership === "auditor" && (
                <div className={styles.field}>
                  <label>{t("subtasks")}</label>
                  <SubtaskList
                    allowCreate
                    subtasks={data.subtasks}
                    onClick={handleOpenCreateSubtask}
                  />
                </div>
              )}
              {(!!data.id || data.editModeAction) && (
                <div className={styles.deleteWrapper}>
                  <Button handleClick={handleDeleteStep} color={"red"} tertiary>
                    {t("delete_the_task")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default CreateEditStep;
