import axios from "axios";

import helper from "../consts/helper";
import { logout } from "./userService";

const JWT_TOKEN = helper.JWT_TOKEN;
const BASE_URL = process.env.REACT_APP_API_URL;

const getToken = (token: string | null) => token ?? localStorage.getItem(JWT_TOKEN);

const setNewToken = (response: any) => {
  if (response.headers["x-new-token"]) {
    localStorage.setItem(JWT_TOKEN, response.headers["x-new-token"]);
  }
  return response;
};

const handleRequestError = (error: any) => {
  if (error?.response?.status === 401) {
    localStorage.setItem(helper.LAST_VISITED_PAGE, window.location.pathname);
    console.error("Unauthorized, logging out...");
    logout();
  } else if (error?.response?.status === 404) {
    console.error("Resource not found", error);
  } else {
    console.error("An error occurred", error);
  }
  return Promise.reject(error);
};

function ApiNew({
  token = null,
  contentType = "application/json",
}: { token?: string | null; contentType?: string } = {}) {
  const headers = {
    Accept: "application/json",
    "Content-Type": contentType,
    Authorization: `Bearer ${getToken(token)}`,
  };

  const api = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
    headers: headers,
  });

  api.interceptors.request.use((request) => request);

  api.interceptors.response.use(
    (response) => setNewToken(response),
    (error) => handleRequestError(error)
  );

  return api;
}

export default ApiNew;
