import classNames from "classnames";

import styles from "./Label.module.scss";

type LabelProps = {
  type?: "primary";
  nowrap?: boolean;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

const Label = ({ children, type, nowrap, className, ...restAttr }: LabelProps) => {
  const classes = classNames(styles.label, className, {
    [styles[type!]]: type,
    [styles.nowrap]: nowrap,
  });

  return (
    <label className={classes} {...restAttr}>
      {children}
    </label>
  );
};

export default Label;
