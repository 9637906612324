import { Checkbox, Col, Form, message, ModalProps, Radio, Row, Space } from "antd";
import type { UploadFile } from "antd/es";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import StyledModal from "../../../../../components/ui-components/StyledModal";
import { Button, Label } from "../../../../../components/ui-components";

import styles from "./DocumentUploadFileModal.module.scss";
import Attachments from "../../../../../Admin/pages/components/Attachments/Attachments";

type FormData = {
  files: UploadFile[];
  category: string[];
  type: string;
};

const categories = ["Legal", "Shared", "Evidence"];
const types = [
  "Offer",
  "Engagement letter",
  "Letter of acceptance",
  "Letter of representation",
  "Audit report",
  "Invoice",
  "Other",
];

type DocumentUploadFileModalProps = {} & ModalProps;

export default function DocumentUploadFileModal({ ...props }: DocumentUploadFileModalProps) {
  const { control, handleSubmit, watch, setValue } = useForm<FormData>();
  const files = watch("files");

  const onSubmit: SubmitHandler<FormData> = (data) => {
    console.log("Form Data:", data);
    message.success("Form submitted successfully!");
  };

  return (
    <StyledModal
      title='Upload files'
      width={770}
      {...props}
      footer={
        <Row gutter={8} justify='end'>
          <Col span={5}>
            <Button primary color='lightRed'>
              Cancel
            </Button>
          </Col>
          <Col span={5}>
            <Button primary color='blue' onClick={handleSubmit(onSubmit)}>
              Confirm
            </Button>
          </Col>
        </Row>
      }
    >
      <Row gutter={16}>
        <Col span={6}>
          <Label type='primary'>Upload files</Label>
        </Col>
        <Col span={18}>
          <Attachments
            multiple
            newDocument
            attachments={files}
            onAddFile={(files: Array<UploadFile>) => setValue("files", files)}
            onRemoveFile={(deletedId: number) => console.log(deletedId)}
            noLabel
          />
        </Col>
      </Row>
      <Form className={styles.form} onFinish={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          <Col span={6}>
            <Label type='primary'>Category</Label>
          </Col>
          <Col span={18}>
            <Controller
              name='category'
              control={control}
              render={({ field }) => (
                <Checkbox.Group className={styles.categoryGroup} {...field}>
                  <Row gutter={8}>
                    {categories.map((category) => (
                      <Col span={8}>
                        <Checkbox className={styles.categoryButton} key={category} value={category}>
                          {category}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              )}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={6}>
            <Label type='primary'>Type</Label>
          </Col>
          <Col span={18}>
            <Controller
              name='type'
              control={control}
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Space direction='vertical'>
                    {types.map((type) => (
                      <Radio key={type} value={type}>
                        {type}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              )}
            />
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
}
