import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Col, Empty, Row, Space, Switch } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "react-circular-progressbar/dist/styles.css";

import { fetchAudits } from "../../../services/auditService";
import { getPresets } from "../../../api/statusApi";
import { setUserPermissionsRole } from "../../../store/user/actions";
import { removeMeetingData, removeRequestData } from "../../../store/global/actions";

import { Button, Label, Loading } from "../../../components/ui-components";
import CreateCompany from "./components/CreateCompany";
import HeaderAdmin from "../../../Admin/components/HeaderAdmin";
import CreateAudit from "./components/CreateAudit";
import AuditRow from "./components/AuditRow";
import VerifyID from "../../../Admin/components/VerifyID";

import { SettingsIcon } from "../../../assets/icons";
import auditsComplete from "../../../assets/admin/AuditsComplete.svg";
import searchIcon from "../../../assets/images/seacrhIcon.svg";
import logoAudit from "../../../assets/images/logoAudit.svg";
import buttonsAdd from "../../../assets/admin/buttonsAdd.svg";
import { ReactComponent as PlusBig } from "../../../assets/icons/plusBold.svg";

import "../../../Admin/styles/audits.css";
import styles from "./Audits.module.scss";

const Audits = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "audits" });

  const { data: presets = [], isLoading } = useQuery({
    queryKey: ["presets"],
    queryFn: getPresets,
  });
  const { data: audits = [], refetch } = useQuery({
    queryKey: ["audits"],
    queryFn: fetchAudits,
  });

  const platform_role = useSelector((state) => state.user.platform_role);
  const userRole = useSelector((state) => state.user.user_data?.role);

  const [subMenu, setSubMenu] = useState(false);
  const [create, setCreate] = useState(false);
  const [search, setSearch] = useState("");
  const [checked, setChecked] = useState(false);
  const [expandedAudits, setExpandedAudits] = useState([]);

  const onSubmit = (id, role, isAuditor, hasAdminAccess) => {
    dispatch(removeRequestData());
    dispatch(removeMeetingData());
    if (isAuditor || hasAdminAccess || role.overviewAccess) {
      history.push(`/dashboard/${id}/overview`);
    } else if (role.requestsAccess) {
      history.push(`/dashboard/${id}/requests/new`);
    } else if (role.statusAccess) {
      history.push(`/dashboard/${id}/status`);
    } else if (role.documentAccess) {
      history.push(`/dashboard/${id}/documents`);
    } else if (role.meetingAccess) {
      history.push(`/dashboard/${id}/meetings/new`);
    } else {
      history.push(`/dashboard/${id}/settings`);
    }
  };

  const handleClickName = (id, hasAdminAccess) => {
    if (hasAdminAccess) {
      return history.push(`/company/${id}/settings`);
    }
    history.push(`/company/${id}/audits`);
  };

  const openCompanySettings = (id) => {
    history.push(`/company/${id}/settings`);
  };

  useEffect(() => {
    dispatch(setUserPermissionsRole(false));
  });

  const expandAudits = (id) => {
    setExpandedAudits([...expandedAudits, id]);
  };

  const onCloseCreateAudit = () => {
    setCreate(false);
  };

  const showVerification = audits.rights
    ? (audits.rights.hasSignatoryRights || audits.rights.hasSignRequest) &&
      (!audits.rights.signatoryStatus || audits.rights.signatoryStatus === "rejected")
    : false;
  const platformTeamType = audits.rights?.teamType || platform_role?.team;
  const lowerSearch = search.toLowerCase();
  const isEmptyState =
    audits?.companies &&
    !audits.companies.some((d) => d.audits.some((a) => a.name.toLowerCase().includes(lowerSearch)));

  return (
    <>
      <HeaderAdmin />
      <div className={styles.wrapper}>
        {isLoading ? (
          <Loading />
        ) : (
          <div className='audits_content'>
            <div className='audits_content-inner'>
              {showVerification && <VerifyID />}
              <Row gutter={16} align='middle' justify='space-between'>
                <Col>
                  <div className={styles.title}>{t("my_audits")}</div>
                </Col>
                <Col>
                  {(userRole === "super-admin" ||
                    platformTeamType === "audit_firm" ||
                    platformTeamType === "fiduciary") && (
                    <Button
                      small
                      secondary
                      onClick={() => setSubMenu(true)}
                      color='blue'
                      className={styles.addCompanyButton}
                    >
                      <PlusBig />
                      <span>{t("add_company")}</span>
                    </Button>
                  )}
                </Col>
              </Row>
              <div className='audits_search'>
                <div className='audits_search-left'>
                  <img src={searchIcon} alt='search' />
                  <input
                    className='audits_search-input'
                    placeholder={t("search_audits")}
                    onChange={({ target }) => {
                      setSearch(target.value);
                    }}
                  />
                </div>
                <Space size={12}>
                  <Label nowrap>{checked ? t("hide_archived") : t("show_archived")}</Label>
                  <Switch checked={checked} onChange={setChecked} />
                </Space>
              </div>
            </div>

            {isEmptyState ? (
              <Empty />
            ) : (
              audits.companies?.map(({ id, name, audits, logo, hasAdminAccess }) => {
                const filteredAudits = audits.filter((el) =>
                  el.name.toLowerCase().includes(lowerSearch)
                );
                return (
                  (filteredAudits.length > 0 ||
                    name.toLowerCase().includes(lowerSearch) ||
                    !search) && (
                    <div
                      className={`audits_info_block ${audits.length > 0 ? "expanded" : ""}`}
                      key={id}
                    >
                      <div className='audits_info_block_last'>
                        <div className='audits_info_block-inner'>
                          <img
                            className='audits_info_block-logo'
                            alt='logoAudit'
                            src={logo || logoAudit}
                          />
                          <div onClick={() => handleClickName(id, hasAdminAccess)}>
                            <span className='audits_info_block-info_title'>{name}</span>
                          </div>
                        </div>
                        {hasAdminAccess && (
                          <>
                            <div
                              className='audits_info_btn'
                              style={{ marginInline: "auto 12px" }}
                              onClick={() => openCompanySettings(id)}
                            >
                              <SettingsIcon />
                            </div>
                            <div
                              className='audits_info_btn'
                              onClick={() => {
                                setCreate(id);
                              }}
                            >
                              <img className='audits_info_btnAdd' src={buttonsAdd} alt='btnAdd' />
                            </div>
                          </>
                        )}
                      </div>
                      {(expandedAudits.includes(id)
                        ? filteredAudits
                        : filteredAudits.slice(0, 3)
                      ).map(
                        ({
                          id,
                          name,
                          status,
                          documents,
                          requests,
                          meetings,
                          progress,
                          myRole,
                          isAuditor,
                        }) =>
                          progress === 100 ? (
                            <div
                              className='audits_info_data'
                              key={id}
                              onClick={() => {
                                onSubmit(id, myRole, isAuditor, hasAdminAccess);
                              }}
                            >
                              <div className='audits_info_data' key={id}>
                                <div className='audits_info_data_inner audits_info_data_inner-complete '>
                                  <div className='audits_info_block-percent  '>
                                    <div className='progresBar_audits'>
                                      <img src={auditsComplete} alt='auditsComplite' />
                                    </div>
                                  </div>
                                  <div className='audits_info_data_inner_info'>
                                    <span className='audits_info_block-info_subtitle'>{name}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <AuditRow
                              name={name}
                              status={status}
                              documents={documents}
                              requests={requests}
                              progress={progress}
                              myRole={myRole}
                              meetings={meetings}
                              isAuditor={isAuditor}
                              hasAdminAccess={hasAdminAccess}
                              id={id}
                              key={id}
                              onSubmit={onSubmit}
                            />
                          )
                      )}
                      {!expandedAudits.includes(id) && filteredAudits.length > 3 && (
                        <Button handleClick={() => expandAudits(id)} color={"gray"} secondary>
                          {t("show_all_audits")}
                        </Button>
                      )}
                    </div>
                  )
                );
              })
            )}
          </div>
        )}
      </div>
      <CreateCompany isOpen={subMenu} onClose={(e) => setSubMenu(e)} onSuccess={refetch} />
      <CreateAudit id={create} presets={presets} isOpen={create} onClose={onCloseCreateAudit} />
    </>
  );
};

export default Audits;
