import { useContext } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NotificationContext } from "../../../context/notificationContext";

import DropDownUser from "../DropDownUser";
import { Button } from "../../../components/ui-components";

import logoA from "../../../assets/images/logo-primary.svg";
import { ChevronLeftIcon, NotificationIcon } from "../../../assets/icons";

import styles from "./HeaderAdmin.module.scss";

type HeaderAdminProps = {
  dashboard?: boolean;
  backTo?: string;
};

const HeaderAdmin = ({ dashboard, backTo }: HeaderAdminProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const { isUserHasNotification } = useContext(NotificationContext);
  const onNotificationsPage = window.location.pathname === "/notifications";

  return (
    <header className={styles.root}>
      <Link className={styles.link} to={backTo ?? "/audits"}>
        {backTo ? (
          <div className='chat-block'>
            <Button
              secondary
              color='gray'
              className={classNames(styles.backButton, dashboard && styles.backButtonRounded)}
            >
              {!dashboard && <ChevronLeftIcon />}
              {dashboard ? tGlobal("back_to_main") : t("back_audits")}
            </Button>
          </div>
        ) : (
          <img src={logoA} alt='logo' />
        )}
      </Link>
      <Link
        to='/notifications'
        className={`header-notifications ${onNotificationsPage ? "active-page" : ""} ${
          isUserHasNotification ? "unread-notifications" : ""
        }`}
      >
        <NotificationIcon />
      </Link>
      <DropDownUser />
    </header>
  );
};

export default HeaderAdmin;
