import React, { useEffect, useState } from "react";
import { Card } from "antd";
import classes from "../../pages/Company/CompanySettings/Cards/Team/Team.module.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { AivoDarkLogo } from "../../../assets/icons";
import CustomDropdown from "../../../components/ui-components/CustomDropdown/CustomDropdown";
import { superadminGetAuditorFirms, superadminSelectAuditorFirm } from "../../../api/superadminApi";
import InviteUserForm from "../../../components/InviteUserForm/InviteUserForm";
import {
  addAuditor,
  auditorFirmMembers,
  changeAuditorRole,
  removeAuditor,
} from "../../../api/auditApi";
import MemberInfo from "../../../components/MemberRow/MemberInfo";
import MemberActions from "../../../components/MemberRow/MemberActions";
import { getAuditorRoleOptions } from "./Auditor.constants";

const Auditor = ({
  companyData,
  auditId,
  syncDataOnSave,
  isSuperadmin,
  isAuditor,
  isMember,
  isAdmin,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const auditorFirm = companyData.auditorFirm;
  const [auditorFirmOptions, setAuditorFirmOptions] = useState(
    auditorFirm ? [{ label: auditorFirm.name, value: auditorFirm.id }] : []
  );
  const [firmMembers, setFirmMembers] = useState([]);
  const [error, setError] = useState();
  const [auditors, setAuditors] = useState([]);

  const [roleOptions, roleWithActions, initInvite] = getAuditorRoleOptions(auditors);

  const [invite, setInvite] = useState(initInvite);

  useEffect(() => {
    if (isSuperadmin) {
      superadminGetAuditorFirms().then((data) => {
        if (data) {
          setAuditorFirmOptions(
            data.map((i) => ({
              label: i.name,
              value: i.id,
            }))
          );
        }
      });
    }
  }, [isSuperadmin]);

  useEffect(() => {
    if (auditorFirm) {
      auditorFirmMembers(auditId).then((data) => {
        setFirmMembers(data.users);
        setAuditors(data.currentAuditors);
      });
    }
  }, [auditorFirm]);

  const handleAddAuditor = async () => {
    const existingAuditor = auditors.find((i) => i.member.user.email === invite.email);
    if (existingAuditor) {
      const existingUser = existingAuditor.member.user;
      const userName = existingUser.firstName
        ? `${existingUser.firstName} ${existingUser.lastName}`
        : existingUser.email;
      return setError({
        message: `${userName} is already an auditor`,
        userId: existingUser.id,
      });
    }

    if (!invite.teamMemberId) {
      return setError("Can only select auditors from the list");
    }
    try {
      const member = await addAuditor(auditId, {
        teamMemberId: invite.teamMemberId,
        role: invite.role,
      });
      setFirmMembers(firmMembers.filter((i) => i.email !== member.member.user.email));
      setAuditors((state) => [...state, member]);
      setInvite(initInvite);
    } catch (e) {
      setError(e?.response?.data);
      console.log(e);
    }
  };

  const handleChangeRole = async (teamMemberId, role) => {
    if (role === "remove") {
      const member = await removeAuditor(auditId, teamMemberId);
      setAuditors((state) => state.filter((i) => i.id !== teamMemberId));
      setFirmMembers((state) => [...state, member]);
    } else {
      await changeAuditorRole(auditId, teamMemberId, { role });
      setAuditors((state) => state.map((i) => (i.id === teamMemberId ? { ...i, role } : i)));
    }
  };

  const handleAuditorFirmChange = async (name, value) => {
    const res = await superadminSelectAuditorFirm({ auditId, auditorFirmId: value });
    syncDataOnSave(res);
  };

  // const canInvite = (isSuperadmin || isAuditor) && !isAdmin;
  const canInvite = isSuperadmin || isAuditor;

  return (
    <>
      <Card.Grid hoverable={false} className={classes.grid}>
        <div className='auditor-company'>
          <div className='auditor-info'>
            <span className='info-subtitle'>{t("auditor_company")}</span>
            {isSuperadmin ? (
              <CustomDropdown
                placeholder={"Select audit firm"}
                options={auditorFirmOptions}
                value={companyData.auditorFirm?.id}
                onChange={handleAuditorFirmChange}
                name='auditorFirm'
                style={{ width: "223px" }}
                dropdownStyle={{ width: "100%" }}
              />
            ) : (
              <span className='info-company'>{companyData.auditorFirm?.name}</span>
            )}
          </div>

          {companyData.auditorFirm?.logo ? (
            <img src={companyData.auditorFirm.logo} alt='' />
          ) : (
            <AivoDarkLogo />
          )}
        </div>
        {!canInvite && <span className='auditor-list-heading'>{t("auditors")}</span>}
      </Card.Grid>
      {canInvite && (
        <InviteUserForm
          roles={roleOptions}
          invite={invite}
          setInvite={setInvite}
          users={firmMembers}
          onSubmit={handleAddAuditor}
          error={error}
          setError={setError}
        />
      )}
      {auditors
        .sort((a, b) => b.id - a.id)
        .map(({ id, member, role, addedAt }) => {
          const { id: userId, firstName, lastName, email, lastActive } = member.user;
          const memberGridClasses = clsx(classes.grid, classes.memberGrid, {
            [classes.highlighted]: userId === error?.userId,
          });
          return (
            <Card.Grid hoverable={false} className={memberGridClasses}>
              <div className={classes.memberRow}>
                <MemberInfo
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  addedAt={addedAt}
                  lastActive={lastActive}
                />

                <MemberActions
                  allowEdit={!(isMember || (isAdmin && !isAuditor && !isSuperadmin))}
                  name={"auditorFirm"}
                  role={role}
                  tRole={t(`role_${role}`)}
                  roles={roleWithActions}
                  member={member}
                  onChangeRole={(_, value) => handleChangeRole(id, value)}
                  hideSignatory
                />
              </div>
            </Card.Grid>
          );
        })}
    </>
  );
};

export default Auditor;
