import React, { useEffect, useMemo, useState } from "react";
import { Drawer } from "antd";
import styles from "../StatusView.module.scss";
import { Button, Input, Tag } from "../../../../../../components/ui-components";
import { PageTextEditor } from "../../../../components";
import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import FormPicker from "../../../../components/FormPicker/FormPicker";
import Switch from "../../../../../../components/ui-components/Switch/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import htmlToDraft from "html-to-draftjs";
import { ArrowLeftIcon, PlusDarkIcon } from "../../../../../../assets/icons";
import AddAssertion from "../../../../../../components/AddAssertion";
import Assertion from "../../../../../../components/ui-components/Assertion/Assertion";
import { TASK_TYPE_BY_OWNERSHIP } from "../../../Status.constants";
import { useTranslation } from "react-i18next";

const initState = {
  ownership: "auditor",
  name: "",
  type: "",
  description: "",
  assertions: [],
};

const requiredFields = ["name", "type", "ownership"];

const CreateEditSubtask = ({
  // idAudit,
  finishCreate,
  parentStep,
  stepData,
  setStepData,
  finishUpdate,
  finishDelete,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const [data, setData] = useState(initState);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [errors, setErrors] = useState({});
  const [addAssertionPopup, setAddAssertionPopup] = useState(false);
  const isEdit = useMemo(() => typeof stepData === "object" && stepData !== null, [stepData]);

  useEffect(() => {
    if (isEdit) {
      setData(stepData);
      const draft = htmlToDraft(stepData.description);
      const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [stepData]);

  const onEditorStateChange = (editorState) => {
    const rawValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setData((prev) => ({
      ...prev,
      description: rawValue,
    }));
    setEditorState(editorState);
  };

  const handleChangePicker = (name, value) => {
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: false,
    }));

    if (name === "ownership") {
      setData((prev) => ({
        ...prev,
        type: "",
        optional: false,
        helpText: "",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  const handleCreateStep = async () => {
    const errors = Object.entries(data).reduce((acc, [key, value]) => {
      if (requiredFields.includes(key) && !value) {
        acc[key] = true;
      }
      return acc;
    }, {});

    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    if (isEdit) {
      // const res = await updateStep(idAudit, stepData.id, {
      //   ...data,
      //   parentStepId: parentStep.id,
      // });
      // console.log(res);
      // if (!res.step) return;
      const newStep = {
        ...data,
        parentStepId: parentStep.id,
      };

      if (!stepData.editModeAction) {
        newStep.editModeAction = "update";
      }
      finishUpdate(newStep);
    } else {
      // const res = await createStep(idAudit, {
      //   ...data,
      //   order: createStepIdx + 1,
      //   parentStepId: parentStep,
      // });
      // console.log(res);
      //
      // if (!res.step) return;
      const newStep = {
        ...data,
        order: 1,
        parentStepId: parentStep,
        editModeAction: "create",
      };

      finishCreate(newStep);
    }

    handleCloseDrawer();
  };

  const handleDeleteStep = async () => {
    if (!isEdit) setStepData(false);
    // if (!stepData?.id) return;
    // const res = await deleteStep(idAudit, stepData.id);
    // console.log(res);
    finishDelete(stepData);
    handleCloseDrawer();
  };

  const handleCloseDrawer = () => {
    setData(initState);
    setStepData(null);
    setEditorState(EditorState.createEmpty());
    setErrors({});
  };

  const closeAddAssertionPopup = () => {
    setAddAssertionPopup(false);
  };

  const openAddAssertionPopup = () => {
    setAddAssertionPopup(true);
  };

  const handleSelectAssertion = (assertion) => {
    setData({
      ...data,
      assertions: [...data.assertions, assertion],
    });
  };

  const removeAssertion = (id) => {
    setData((state) => ({
      ...state,
      assertions: state.assertions.filter((i) => i.id !== id),
    }));
  };

  const handleBack = async () => {
    await handleCreateStep();
  };

  return (
    <Drawer
      open={!!stepData}
      rootClassName={"edit-step-drawer create-step-drawer create-subtask-drawer"}
      onClose={handleCloseDrawer}
      closable={false}
    >
      <div className={`${styles.drawerContent} ${styles.spacing}`}>
        <div className={styles.drawerHeader}>
          <div className={styles.subtaskHeader}>
            <ArrowLeftIcon onClick={handleBack} />
            <span>{isEdit ? t("edit_subtask") : t("new_subtask")}</span>
          </div>
          {/*<div className={styles.drawerHead}>*/}
          {/*  <span className={styles.drawerTitle}>{isEdit ? "Edit" : "New"} task</span>*/}
          {/*  <div className={styles.actions}>*/}
          {/*    <Button handleClick={handleCloseDrawer} color={"red"} secondary>*/}
          {/*      Discard*/}
          {/*    </Button>*/}
          {/*    <Button handleClick={handleCreateStep} color={"green"} primary>*/}
          {/*      {isEdit ? "Update" : "Create"}*/}
          {/*    </Button>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<FormPicker
            options={OWNERSHIP_OPTIONS}
            error={errors.ownership}
            selected={data.ownership}
            name='ownership'
            onSelect={handleChangePicker}
          />*/}
          <div className={styles.drawerBodyGrid}>
            <Input
              value={data.name}
              error={errors.name}
              name={"name"}
              placeholder={t("task_title")}
              onChange={handleChange}
            />
            {data.ownership === "auditor" && (
              <div className={styles.assertionRow}>
                {data?.assertions
                  ?.sort((a, b) => a.id - b.id)
                  .map((assertion, index) => (
                    <Assertion onClose={removeAssertion} id={assertion.id} key={index}>
                      {assertion.title}
                    </Assertion>
                  ))}
                <Tag color={"gray"} onClick={openAddAssertionPopup}>
                  <PlusDarkIcon style={{ marginRight: 4 }} /> {t("add_assertion")}
                </Tag>
                <AddAssertion
                  isOpen={addAssertionPopup}
                  onClose={closeAddAssertionPopup}
                  values={data.assertions}
                  handleSelect={handleSelectAssertion}
                />
              </div>
            )}
            <FormPicker
              options={TASK_TYPE_BY_OWNERSHIP[data.ownership]}
              error={errors.type}
              selected={data.type}
              name='type'
              onSelect={handleChangePicker}
              label={t("task_type")}
            />
            <PageTextEditor
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              bordered={true}
              readOnly={false}
              label={tGlobal("description")}
              value={data.value}
              name={"description"}
              hiddenInput={false}
            />
            {data.ownership === "auditor" && (
              <div className={styles.field}>
                <label>{t("optional_task")}</label>
                <FormControlLabel
                  style={{ marginLeft: "4px" }}
                  control={
                    <Switch
                      checked={data.optional}
                      onChange={() => {
                        handleChangePicker("optional", !data.optional);
                      }}
                    />
                  }
                  label={t("optional_task_description")}
                />
              </div>
            )}
            {data.type === "simple" && (
              <div className={styles.field}>
                <label>{t("help_text_label")}</label>
                <Input
                  value={data.helpText}
                  name={"helpText"}
                  onChange={handleChange}
                  placeholder={t("help_text_placeholder")}
                />
              </div>
            )}
            <div className={styles.deleteWrapper}>
              <Button handleClick={handleDeleteStep} color={"red"} tertiary>
                {t("delete_the_subtask")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default CreateEditSubtask;
