export const Options = (t, customOptionTypes = []) => ({
  optionType: [
    ...customOptionTypes.map((i) => ({
      value: i.auditType,
      label: i.auditType,
    })),
    {
      value: "",
      label: t("other_audit_option"),
    },
  ],
  optionAccount: [
    {
      value: "swiss",
      label: t("swiss_obligations_option"),
    },
    {
      value: "swiss_gaap_option",
      label: t("Swiss GAAP FER"),
    },
    {
      value: "ifrs",
      label: t("ifrs_option"),
    },
  ],
});
