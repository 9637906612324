import React, { useEffect, useMemo, useState } from "react";
import { convertToRaw } from "draft-js";
import { useParams } from "react-router-dom";

import { Label } from "../../../../components/ui-components";
import { EditButtons, RichTextEditor } from "../index";
import {
  extractLinks,
  parseEditorState,
  updateLinkText,
} from "../../../../consts/DOM/extractLinks";
import AttachmentRow from "../Attachments/components/AttachmentRow/AttachmentRow";

import styles from "./PageTextEditor.module.scss";
import draftToHtml from "draftjs-to-html";
import useDeepEqualEffect from "../../../../hooks/useDeepEqualEffect";
import { appendDocumentToEditorState } from "../../../../utils/appendDocumentToEditorState";

const PageTextEditor = ({
  openEditMode,
  acceptChanges,
  discardChanges,
  isEditMode = false,
  readOnly = false,
  value,
  name,
  editorState,
  onEditorStateChange,
  label,
  hiddenInput = false,
  bordered = false,
  onBlur,
  onFocus,
  documents = [],
  allowAttachments,
  disabled,
}) => {
  const { id: auditId } = useParams();

  const [isDocumentPopupActive, setIsDocumentPopupActive] = useState(false);
  const [repopulateLinkCounter, setRepopulateLinkCounter] = useState(0);

  const populatedDocuments = useMemo(() => {
    const links = extractLinks(editorState, documents);
    const populatedLinks = links
      .map((link) => {
        const document = documents.find((doc) => doc.id === link.id);
        return document && { ...document, url: link.url };
      })
      .filter(Boolean);

    if (!repopulateLinkCounter) {
      setRepopulateLinkCounter((prev) => prev + 1);
    }
    return populatedLinks;
  }, [editorState, documents]);

  useEffect(() => {
    if (populatedDocuments.length) {
      const newState = updateLinkText(editorState, populatedDocuments);
      onEditorStateChange(newState, name);
    }
  }, [repopulateLinkCounter]);

  useDeepEqualEffect(() => {
    setRepopulateLinkCounter((prev) => prev + 1);
  }, [documents]);

  useEffect(() => {
    const editors = document.querySelectorAll(".editor-wrapper");
    const editorsLength = editors.length;
    editors.forEach((link, idx) => {
      link.style.zIndex = editorsLength - idx;
    });
  }, []);

  const handleAttachDocuments = (documents) => {
    let newEditorState = editorState;
    if (Array.isArray(documents)) {
      for (let i = 0; i < documents.length; i++) {
        const document = documents[i];
        const isLast = i === documents.length - 1;

        newEditorState = appendDocumentToEditorState(newEditorState, document, auditId, isLast);
      }
    } else {
      newEditorState = appendDocumentToEditorState(newEditorState, documents, auditId);
    }

    onEditorStateChange(newEditorState, name);
    if (onBlur) {
      const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
      const e = {
        target: {
          name,
          value: html,
        },
      };
      onBlur?.(e, name);
    }
    setIsDocumentPopupActive(false);
  };

  const handleEditorChange = (newEditorState) => {
    if (!allowAttachments) return onEditorStateChange(newEditorState, name);

    const parsedEditorState = parseEditorState(newEditorState, documents, true);

    if (parsedEditorState) {
      return onEditorStateChange(parsedEditorState, name);
    }

    onEditorStateChange(newEditorState, name);
  };

  return (
    <div className={styles.fieldWrapper}>
      <div className={styles.root}>
        <Label>{label}</Label>
        {isEditMode && (
          <div className={styles.editButtonsWrapper}>
            <EditButtons name={name} discardClick={discardChanges} acceptClick={acceptChanges} />
          </div>
        )}
        <div
          className={`editor-wrapper ${styles.textEditorWrapper} editor-${name}`}
          onClick={() => !disabled && openEditMode?.(name)}
        >
          <RichTextEditor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            readOnly={readOnly}
            value={value}
            name={name}
            hiddenInput={hiddenInput}
            bordered={bordered}
            onBlur={onBlur}
            onFocus={onFocus}
            documents={documents}
            selectedDocuments={populatedDocuments}
            allowAttachments={allowAttachments}
            auditId={auditId}
            handleAttachDocuments={handleAttachDocuments}
            isDocumentPopupActive={isDocumentPopupActive}
            setIsDocumentPopupActive={setIsDocumentPopupActive}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={styles.attachedEntities}>
        {populatedDocuments.map((doc) => (
          <AttachmentRow handleQuote={handleAttachDocuments} key={doc.id} file={doc} />
        ))}
      </div>
    </div>
  );
};

export default PageTextEditor;
