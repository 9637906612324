import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, Space, Col, Row } from "antd";

import { sendData } from "../../../../../api/userApi";
import { EMAIL_VALIDATION_PATTERN, PHONE_REGEX } from "../../../../../consts/regExp";
import useRegex from "../../../../../hooks/useRegex";
import { setUserData } from "../../../../../store/user/actions";
import { setProfileDetailsUnHighlighted } from "../../../../../store/global/actions";

import { Input, Label } from "../../../../../components/ui-components";
import StyledPhoneInput from "../../../../../components/ui-components/StyledPhoneInput";
import SelectCountryAutocomplete from "../../../../../components/ui-components/SelectCountryAutocomplete";
import CardActions from "../../../../../Admin/pages/components/CardActions";
import AccountDetailsConfirmUpdatePopup from "./AccountDetailsConfirmUpdatePopup";
import FileUpload from "../../../../../components/FileUpload";
import ChangePhoneConfirmationPopup from "./ChangePhoneConfirmationPopup";

import styles from "./AccountSettingsProfile.module.scss";

const AccountSettingsProfile = ({ userData, syncDataOnSave }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const { isProfileDetailsHighlighted } = useSelector((state) => state.global);

  const [isOpenPhoneConfirmPopup, setIsOpenPhoneConfirmPopup] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    firstName: userData.firstName ?? "",
    lastName: userData.lastName ?? "",
    email: userData.email ?? "",
    phone: userData.phone ?? "",
    title: userData.title ?? "",
    logo: userData?.logo ?? "",
    logoURL: "",
    country: userData?.country ?? "",
  });
  const [phone, setPhone] = useState("");

  const isCorrectEmail = useRegex(data.email.toLowerCase(), EMAIL_VALIDATION_PATTERN);
  const isCorrectPhone = useRegex(data.phone, PHONE_REGEX);

  // Remove the highlight effect from the fields on unmount
  useEffect(() => {
    return () => {
      dispatch(setProfileDetailsUnHighlighted());
    };
  }, [dispatch]);

  const handleSubmit = async () => {
    if (isCorrectPhone && isCorrectEmail) {
      if (userData?.signatoryStatus === "verified" && hasChanged && !isOpenConfirmPopup) {
        setIsOpenConfirmPopup(true);
        return;
      }
      try {
        const res = await sendData(data);
        if (res.verifyPhone) {
          setPhone(data.phone);
          setIsOpenPhoneConfirmPopup(true);
        }
        const baseData = {
          ...data,
          phone: res.phone,
          logo: res.logo,
          signatoryStatus: res.signatoryStatus,
        };
        dispatch(setUserData({ ...userData, ...baseData }));
        setData({
          ...baseData,
          logoURL: "",
        });
        syncDataOnSave(baseData);
        setIsOpenConfirmPopup(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(true);
    }
  };

  const inputData = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setError(false);
  };

  const handleCloseConfirmPopup = () => {
    setIsOpenConfirmPopup(false);
  };

  const handleCancel = () => {
    setData({
      firstName: userData.firstName ?? "",
      lastName: userData.lastName ?? "",
      email: userData.email ?? "",
      phone: userData.phone ?? "",
      title: userData.title ?? "",
      logo: userData.logo ?? "",
      country: userData.country ?? "",
    });
  };

  const handleClosePhoneConfirmPopup = () => setIsOpenPhoneConfirmPopup(false);

  const updateDataOnConfirm2FA = () => {
    setData((prev) => ({
      ...prev,
      phone,
    }));
    dispatch(setUserData({ ...userData, phone }));
    syncDataOnSave({ ...userData, phone });
  };

  const setIsFieldWarningVisible = (values) => {
    if (userData?.signatoryStatus === "verified") {
      if (!Array.isArray(values)) {
        return userData[values] !== data[values];
      } else {
        return values.some((i) => userData[i] !== data[i]);
      }
    }
  };

  const hasChanged =
    data.firstName !== userData.firstName ||
    data.lastName !== userData.lastName ||
    data.email !== userData.email ||
    data.phone !== userData.phone ||
    !!data.logoURL ||
    data.logo !== userData.logo ||
    data.title !== userData.title ||
    data.country !== userData.country;

  return (
    <>
      <Card.Grid className={styles.root} hoverable={false}>
        <Space.Compact direction='vertical' block className={styles.root}>
          <Row gutter={24} span={24}>
            <Col span={8}>
              <Label type='primary'>{t("account_profile_image")}</Label>
            </Col>
            <Col span={16}>
              <FileUpload
                title={tGlobal("select_picture")}
                file={data.logoURL || data.logo}
                subtitle={"JPG, PNG"}
                accept={{
                  "image/jpeg": [],
                  "image/png": [],
                }}
                onChange={(file) => {
                  setData({
                    ...data,
                    logo: file,
                    logoURL: file ? URL.createObjectURL(file) : "",
                  });
                  // inputData("logo", file || "");
                  // inputData("logoURL", file ? URL.createObjectURL(file) : "");
                }}
              />
            </Col>
          </Row>
          <Row gutter={24} span={24}>
            <Col span={8}>
              <Label type='primary'>{t("account_full_name")}</Label>
            </Col>
            <Col span={16}>
              <div className={styles.splitInput}>
                <Input
                  placeholder='John'
                  type='name'
                  isHighlighted={isProfileDetailsHighlighted}
                  value={data.firstName}
                  onChange={({ target }) => {
                    inputData("firstName", target.value);
                  }}
                />
                <Input
                  placeholder='Doe'
                  type='name'
                  isHighlighted={isProfileDetailsHighlighted}
                  value={data.lastName}
                  onChange={({ target }) => {
                    inputData("lastName", target.value);
                  }}
                />
              </div>
              {setIsFieldWarningVisible(["firstName", "lastName"]) && (
                <div className={styles.textWarning}>
                  {t("account_full_name_verification_warning")}
                </div>
              )}
              {isProfileDetailsHighlighted && (
                <div className={styles.textWarning}>{t("account_full_name_matches_warning")}</div>
              )}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Label type='primary'>{tGlobal("email")}</Label>
            </Col>
            <Col span={16}>
              <Input
                error={error && !isCorrectEmail}
                errorMessage={tGlobal("input_email_error")}
                placeholder='doe@mail.com'
                value={data.email}
                type='email'
                onChange={({ target }) => {
                  inputData("email", target.value);
                }}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Label type='primary'>{tGlobal("phone_number")}</Label>
            </Col>
            <Col span={16}>
              <StyledPhoneInput
                isHighlighted={isProfileDetailsHighlighted}
                value={data.phone}
                error={error && !isCorrectPhone}
                errorMessage='Incorrect phone'
                onChange={(value) => inputData("phone", value)}
              />
              {setIsFieldWarningVisible("phone") && (
                <div className={styles.textWarning}>{t("account_phone_verification_warning")}</div>
              )}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Label type='primary'>{t("job_title")}</Label>
            </Col>
            <Col span={16}>
              <Input
                placeholder='CEO'
                value={data.title}
                onChange={({ target }) => {
                  inputData("title", target.value);
                }}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Label type='primary'>{tGlobal("country")}</Label>
            </Col>
            <Col span={16}>
              <SelectCountryAutocomplete
                isHighlighted={isProfileDetailsHighlighted}
                value={data.country}
                onChange={(val) => inputData("country", val)}
              />
              {setIsFieldWarningVisible("country") && (
                <div className={styles.textWarning}>
                  {t("account_country_verification_warning")}
                </div>
              )}
              {isProfileDetailsHighlighted && (
                <div className={styles.textWarning}>{t("account_country_matches_warning")}</div>
              )}
            </Col>
          </Row>
        </Space.Compact>
      </Card.Grid>
      {hasChanged && <CardActions onSubmit={handleSubmit} onCancel={handleCancel} />}
      <AccountDetailsConfirmUpdatePopup
        isOpen={isOpenConfirmPopup}
        onClose={handleCloseConfirmPopup}
        onConfirm={handleSubmit}
      />
      <ChangePhoneConfirmationPopup
        phone={phone}
        open={isOpenPhoneConfirmPopup}
        onClose={handleClosePhoneConfirmPopup}
        onSubmit={updateDataOnConfirm2FA}
      />
    </>
  );
};

export default AccountSettingsProfile;
