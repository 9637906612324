import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { updateAudit } from "../../../api/auditApi";
import { getRequests } from "../../../api/requestsPage";
import { getAllMeetings } from "../../../api/meetingsAPI";
import { getDocumentBacklinks } from "../../../api/documentApi";
import { getPresetPreview, getStatusAudit } from "../../../api/statusApi";
import { REQUEST_STATUSES } from "../../../consts/enums";
import { useFetch } from "../../../hooks";

import { DualScrollWrapper } from "../components";
import { Loading } from "../../../components/ui-components";
import StatusList from "./components/StatusList";
import StatusView from "./components/StatusView";
import { useQuery } from "@tanstack/react-query";

import styles from "./Status.module.scss";

const initStatusFilters = Object.values(REQUEST_STATUSES);

const StatusPage = () => {
  const { id: idAudit } = useParams();
  const userRole = useSelector((state) => state.user.user_role);
  const {
    isLoading: isStatusLoading,
    data,
    stealthRefetchAsync,
    setData,
  } = useFetch(getStatusAudit, idAudit);
  const { data: requestData, isLoading: isRequestLoading } = useFetch(
    getRequests,
    idAudit,
    initStatusFilters
  );
  const { data: meetingData, isLoading: isMeetingLoading } = useFetch(getAllMeetings, idAudit);
  const { data: documents, loading: isDocumentLoading } = useQuery({
    staleTime: Infinity,
    queryKey: ["documents"],
    queryFn: () => getDocumentBacklinks(idAudit),
  });

  const [selectedStatusGroup, setSelectedStatusGroup] = useState(null);
  const [updatedFields, setUpdatedFields] = useState({});
  const [updatedSteps, setUpdatedSteps] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [preview, setPreview] = useState(null);
  const [filter, setFilter] = useState([]);
  const { hasAdminPermissions } = userRole || {};

  const [audit, selectedGroupData, hasAnyStatusProgress] = useMemo(() => {
    if (preview) {
      const newAudit = { ...data.audit, ...updatedFields, statusGroups: preview.groups };
      const newSelectedGroup = preview.groups.findIndex((i) => i.id === selectedStatusGroup);
      let newSelectedGroupData;
      if (newSelectedGroup === -1) newSelectedGroupData = preview.groups[0];
      else newSelectedGroupData = preview.groups[newSelectedGroup];
      setSelectedStatusGroup(newSelectedGroupData?.id);
      return [newAudit, newSelectedGroupData, false];
    }

    if (!data) return [null, null, false];
    setSelectedStatusGroup((state) => {
      if (state) return state;
      const groups = data?.audit?.statusGroups;
      let selectedGroup = groups
        ?.sort((a, b) => b.order - a.order)
        ?.find((i) => i.steps.find((j) => j.status === "in_progress" || j.status === "done"));

      if (!selectedGroup) {
        selectedGroup = groups?.[0];
      }

      return selectedGroup?.id;
    });
    const newAudit = { ...data?.audit, ...updatedFields };

    const statusGroups = newAudit?.statusGroups.sort((a, b) => a.order - b.order);
    const idx = statusGroups?.findIndex((i) => i.id === selectedStatusGroup);

    // TODO: cannot implement it for any status other than "not_started" because there's always
    //  1 inProgress task, should I ignore it or let inProgress not affect condition?
    const hasAnyStatusProgress = statusGroups?.some((i) =>
      i.steps.some((j) => j.status !== "not_started" && j.status !== "in_progress")
    );

    if (idx === -1) {
      const groups = data?.audit?.statusGroups;
      let newSelectedGroup = groups
        ?.sort((a, b) => b.order - a.order)
        ?.find((i) => i.steps.find((j) => j.status === "in_progress" || j.status === "done"));

      if (!newSelectedGroup) {
        newSelectedGroup = groups?.[0];
      }

      setSelectedStatusGroup(newSelectedGroup?.id);
      return [newAudit, newSelectedGroup, hasAnyStatusProgress];
    }
    return [newAudit, newAudit?.statusGroups?.[idx], hasAnyStatusProgress];
  }, [data, updatedFields, selectedStatusGroup, preview]);

  const syncData = async (data) => {
    setUpdatedFields((state) => ({
      ...state,
      ...data,
    }));
    await updateAudit(idAudit, data);
  };

  useEffect(() => {
    if (audit && idAudit && audit?.id !== +idAudit) {
      window.location.reload();
    }
  }, [idAudit, audit]);

  const previewPreset = async (name) => {
    const preview = await getPresetPreview(idAudit, name);
    const sortedGroups = preview.preset.groups.sort((a, b) => a.order - b.order);
    setPreview({
      ...preview,
      groups: sortedGroups,
    });
  };

  const isUnsynced = useMemo(() => {
    return !audit?.isAuditUpToDate || !audit?.isTemplateUpToDate;
  }, [audit]);

  const isLoading =
    !userRole ||
    !audit ||
    isStatusLoading ||
    isRequestLoading ||
    isMeetingLoading ||
    isDocumentLoading;

  return (
    <div className={styles.wrapper}>
      {!isLoading ? (
        <DualScrollWrapper
          leftSidebar={
            <StatusList
              activeGroup={selectedStatusGroup}
              setActiveGroup={setSelectedStatusGroup}
              syncData={syncData}
              editMode={editMode}
              setEditMode={setEditMode}
              data={audit}
              setData={setData}
              companyPresets={data?.presets || []}
              setUpdatedSteps={setUpdatedSteps}
              previewPreset={previewPreset}
              setPreview={setPreview}
              isUnsynced={isUnsynced}
              hasAdminPermissions={hasAdminPermissions}
              filter={filter}
              setFilter={setFilter}
              hasAnyStatusProgress={hasAnyStatusProgress}
            />
          }
        >
          {selectedStatusGroup && (
            <StatusView
              data={selectedGroupData}
              setData={setData}
              selectedStatusGroup={selectedStatusGroup}
              editMode={editMode && !preview}
              updatedSteps={updatedSteps}
              idAudit={idAudit}
              hasAdminPermissions={hasAdminPermissions}
              stealthRefetchAsync={stealthRefetchAsync}
              requests={requestData?.requests || []}
              meetings={meetingData?.meetings || []}
              documents={documents || []}
              isUnsynced={isUnsynced}
              ownershipFilter={filter}
            />
          )}
        </DualScrollWrapper>
      ) : (
        <Loading position='absoluteCenter' />
      )}
    </div>
  );
};

export default StatusPage;
