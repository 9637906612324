import React from "react";
import { Col, Row, Input, message } from "antd";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { Button, Label } from "../../../../../../components/ui-components";
import { ReactComponent as CopyLinkIcon } from "../../../../../../assets/icons/copy-link.svg";

import styles from "./DocumentInviteGuestsForm.module.scss";
import StyledPhoneInput from "../../../../../../components/ui-components/StyledPhoneInput";
import { inviteDocumentGuest } from "../../../../../../api/documentApi";
import { IDocumentAccess } from "../../../../../../interfaces/Document";
import { PHONE_REGEX } from "../../../../../../consts/regExp";
import FormField from "../../../../../../components/ui-components/FormField";

type Params = {
  id: string;
  documentId: string;
};

type DocumentInviteGuestsInputs = {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  phone: string;
  emailMessage: string;
};

interface DocumentInviteGuestsFormProps {
  handleSetTab: (key: string) => void;
  handleAddUser: (access: IDocumentAccess) => void;
  handleCopyLink: () => void;
}

export default function DocumentInviteGuestsForm({
  handleSetTab,
  handleAddUser,
  handleCopyLink,
}: DocumentInviteGuestsFormProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const { id, documentId } = useParams<Params>();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DocumentInviteGuestsInputs>();

  const onSubmit: SubmitHandler<DocumentInviteGuestsInputs> = async (data) => {
    try {
      const res = await inviteDocumentGuest(data, id, documentId);
      handleAddUser(res.access);
      reset();
      handleSetTab("overview");
    } catch (e) {
      message.error("Error inviting guest");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.body}>
        <div className={styles.footnote}>
          <Trans
            t={t}
            i18nKey='invite_guests_form_footnote'
            components={{ a: <Link className={styles.link} to='/' /> }}
          />
        </div>
        <Row gutter={[24, 16]}>
          <Col span={8}>
            <Label type='primary'>{t("invite_guests_form_name")}</Label>
          </Col>
          <Col span={16}>
            <Row gutter={16}>
              <Col span={12}>
                <FormField
                  name='firstName'
                  control={control}
                  errors={errors}
                  placeholder={tGlobal("first_name")}
                  className={styles.formItem}
                />
              </Col>
              <Col span={12}>
                <FormField
                  name='lastName'
                  control={control}
                  errors={errors}
                  placeholder={tGlobal("last_name")}
                  className={styles.formItem}
                />
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Label type='primary'>{t("invite_guests_form_company")}</Label>
          </Col>
          <Col span={16}>
            <FormField
              name='company'
              control={control}
              errors={errors}
              className={styles.formItem}
            />
          </Col>

          <Col span={8}>
            <Label type='primary'>{tGlobal("email")}</Label>
          </Col>
          <Col span={16}>
            <FormField
              name='email'
              control={control}
              errors={errors}
              placeholder='doe@mail.com'
              rules={{ required: true }}
              className={styles.formItem}
            />
          </Col>

          <Col span={8}>
            <Label type='primary'>{tGlobal("phone_number")}</Label>
          </Col>
          <Col span={16}>
            <Controller
              name='phone'
              control={control}
              defaultValue={"+41"}
              rules={{
                required: "Phone number is required",
                pattern: {
                  value: PHONE_REGEX,
                  message: "Phone number is invalid",
                },
              }}
              render={({ field }) => (
                <StyledPhoneInput
                  {...field}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors.phone}
                  errorMessage={errors.phone?.message}
                />
              )}
            />
          </Col>

          <Col span={8}>
            <Label type='primary'>{t("invite_guests_form_email_message")}</Label>
          </Col>
          <Col span={16}>
            <FormField
              name='emailMessage'
              control={control}
              errors={errors}
              render={({ field }) => (
                <Input.TextArea autoSize={{ minRows: 5, maxRows: 15 }} {...field} />
              )}
              className={styles.formItem}
            />
          </Col>
        </Row>
      </div>

      <div className={styles.footer}>
        <Row gutter={10} justify='space-between' align='middle'>
          <Col>
            <Button
              type='button'
              tertiary
              color='blue'
              className={styles.button}
              onClick={handleCopyLink}
            >
              <CopyLinkIcon />
              {tGlobal("copy_link")}
            </Button>
          </Col>
          <Col span={5}>
            <Button fullWidth primary color='blue' type='submit' className={styles.button}>
              {tGlobal("send")}
            </Button>
          </Col>
        </Row>
      </div>
    </form>
  );
}
