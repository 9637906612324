import React, { useEffect, useState } from "react";
import { ChevronBottom } from "../../../assets/icons";
import { Dropdown } from "antd";
import DropdownMenuOverlay from "../DropdownMenuOverlay";
import OutsideClickHandler from "../../../consts/detectOutSideClick";
import "./CustomAntDropdown.scss";

const CustomAntDropdown = ({
  options,
  placeholder = "Select an option",
  value,
  onChange,
  setNoOutside,
  type,
  headerStyle,
  name,
  style,
  dropdownStyle,
  disabled,
  error,
  CustomOverlay,
  t,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (e) => {
    e.domEvent.preventDefault();
    if (e.key === "disabled") return;
    if (onChange) {
      onChange(name, e.key);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setNoOutside?.(isOpen);
  }, [isOpen]);

  const selectedOption = options.find((opt) => opt.value.toString() === value?.toString());

  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isOpen) return;
    setIsOpen(true);
  };

  const handleOutsideClick = () => {
    if (!isOpen) return;
    setIsOpen(false);
  };

  const menu = (
    <OutsideClickHandler callback={handleOutsideClick}>
      {CustomOverlay ? (
        <CustomOverlay
          handleOptionClick={handleOptionClick}
          options={options}
          value={value}
          style={dropdownStyle}
          t={t}
        />
      ) : (
        <DropdownMenuOverlay
          handleOptionClick={handleOptionClick}
          options={options}
          value={value}
          style={dropdownStyle}
          t={t}
        />
      )}
    </OutsideClickHandler>
  );

  return (
    <div
      className={`dropdown-container ${type} ${disabled ? "disabled" : ""} ${
        isOpen ? "open" : ""
      } ${error ? "error" : ""}`}
      style={style}
    >
      <Dropdown trigger={["click"]} overlay={menu} visible={isOpen}>
        <div className='dropdown-header' onClick={handleOpen} style={headerStyle}>
          {selectedOption ? selectedOption.label : placeholder}
          <ChevronBottom className='dropdown-arrow' />
        </div>
      </Dropdown>
    </div>
  );
};

export default CustomAntDropdown;
