const ColorPickerIcon = ({ ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    {...props}
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M13.804 3.88401L11.4473 1.52867C11.3231 1.40422 11.1547 1.33395 10.9788 1.3332C10.803 1.33245 10.634 1.40128 10.5087 1.52467L4.73002 7.21334C4.63994 7.30249 4.57686 7.41526 4.54802 7.53867L3.86535 10.4967L2.66602 12H4.55135L5.31268 11.2473L7.70468 10.6953C7.82468 10.6673 7.93468 10.6067 8.02268 10.5207L13.8007 4.83067C13.8634 4.76892 13.9132 4.69536 13.9474 4.61424C13.9815 4.53312 13.9992 4.44604 13.9995 4.35803C13.9999 4.27003 13.9827 4.18283 13.9492 4.10147C13.9156 4.02011 13.8663 3.9462 13.804 3.88401ZM7.55802 9.10601L6.14402 7.69267L10.9727 2.93867L12.386 4.35201L7.55802 9.10601ZM2.66602 13.3333H13.3327V14.6667H2.66602V13.3333Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
  </svg>
);

export default ColorPickerIcon;
