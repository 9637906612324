import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Col, Form, message, Row } from "antd";
import { Input, Label, Button } from "../../../../../../components/ui-components";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import StyledPhoneInput from "../../../../../../components/ui-components/StyledPhoneInput";
import { PHONE_REGEX } from "../../../../../../consts/regExp";
import styles from "./EditUser.module.scss";
import { User } from "../../../../../../types/user";
import { superadminUpdateUser } from "../../../../../../api/superadminApi";
import FormField from "../../../../../../components/ui-components/FormField";
import StyledModal from "../../../../../../components/ui-components/StyledModal";

interface EditUserProps {
  editUser: User;
  close: (userData?: UserEditInputs) => void;
}

interface UserEditInputs {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
}

const EditUser = ({ editUser, close }: EditUserProps) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors, defaultValues },
  } = useForm<UserEditInputs>({ defaultValues: editUser });

  const handleClose = () => {
    reset();
    close();
  };

  useEffect(() => {
    reset(editUser);
  }, [editUser, reset]);

  const onSubmit = async (data: UserEditInputs) => {
    try {
      await superadminUpdateUser(editUser.id, data);
      close(data);
      reset();
    } catch (e: any) {
      if (e.response?.data?.message === "Duplicate entry") {
        setError("email", { message: "Email is already used by another user" });
      } else {
        message.error("Error updating user");
      }
    }
  };

  console.log({ editUser, defaultValues });
  return (
    <StyledModal
      open={!!editUser.id}
      onCancel={handleClose}
      width={720}
      title='Edit User'
      className={styles.root}
      classNames={{
        body: styles.body,
      }}
      footer={null}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.body}>
          <Row gutter={[24, 20]}>
            <Col span={8}>
              <Label type='primary'>{t("account_full_name")}</Label>
            </Col>
            <Col span={16}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormField
                    name='firstName'
                    control={control}
                    errors={errors}
                    placeholder={tGlobal("first_name")}
                    className={styles.formItem}
                  />
                </Col>
                <Col span={12}>
                  <FormField
                    name='lastName'
                    control={control}
                    errors={errors}
                    placeholder={tGlobal("last_name")}
                    className={styles.formItem}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Label type='primary'>{tGlobal("email")}</Label>
            </Col>
            <Col span={16}>
              <FormField
                name='email'
                control={control}
                errors={errors}
                placeholder='doe@mail.com'
                rules={{
                  required: tGlobal("email_required"),
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: tGlobal("email_invalid"),
                  },
                }}
                className={styles.formItem}
              />
            </Col>

            <Col span={8}>
              <Label type='primary'>{tGlobal("phone_number")}</Label>
            </Col>
            <Col span={16}>
              <FormField
                name='phone'
                control={control}
                errors={errors}
                rules={{
                  required: tGlobal("phone_required"),
                  pattern: {
                    value: PHONE_REGEX,
                    message: tGlobal("phone_invalid"),
                  },
                }}
                render={({ field }) => (
                  <StyledPhoneInput
                    {...field}
                    onChange={(value) => field.onChange(value)}
                    error={!!errors.phone}
                  />
                )}
              />
            </Col>

            <Col span={8}>
              <Label type='primary'>{tGlobal("home_company")}</Label>
            </Col>
            <Col span={16}>
              <FormField
                name='company'
                control={control}
                errors={errors}
                className={styles.formItem}
                disabled
              />
            </Col>
          </Row>
        </div>

        <div className={styles.footer}>
          <Row gutter={10} justify='end' align='middle'>
            <Col>
              <Button
                type='button'
                secondary
                color='red'
                className={styles.button}
                onClick={handleClose}
              >
                {tGlobal("cancel")}
              </Button>
            </Col>
            <Col span={5}>
              <Button fullWidth primary color='blue' type='submit' className={styles.button}>
                {tGlobal("save")}
              </Button>
            </Col>
          </Row>
        </div>
      </form>
    </StyledModal>
  );
};

export default EditUser;
