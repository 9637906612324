import { Button, Dropdown } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getHumanFileSize } from "../../../../../../utils/files";
import { downloadDocument } from "../../../../../../api/documentApi";
import useDeepEqualEffect from "../../../../../../hooks/useDeepEqualEffect";
import { downloadFileFromURL } from "../../../../../../utils/downloadFileFromURL";

import FileExtension from "../../../../../components/FileExtension/FileExtension";
import EditableDocumentName from "../../../../../../components/EditableDocumentName";
import ReplaceDocumentPopover from "../../../../../../pages/dashboard/Documents/components/ReplaceDocumentPopover";

import {
  DownloadRoundedIcon,
  QuoteIcon,
  ThreeDotsIcon,
  TrashIcon,
} from "../../../../../../assets/icons";
import { ReactComponent as RepeatIcon } from "../../../../../../assets/icons/repeat.svg";

import styles from "./AttachmentRow.module.css";

type AttachmentRowProps = {
  file: any;
  progress?: any;
  deleteDocument?: any;
  href?: string;
  handleQuote?: Function;
  onClick?: () => void;
};

interface QueryParams {
  id: string;
}

const AttachmentRow = ({
  href,
  file,
  progress,
  deleteDocument,
  handleQuote,
  onClick,
}: AttachmentRowProps) => {
  const { id: auditId } = useParams<QueryParams>();
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isReplacePopupOpen, setIsReplacePopupOpen] = useState(false);
  const [data, setData] = useState(file);
  const isUploading = progress !== undefined;
  const progressPercentage = progress / 100;

  useDeepEqualEffect(() => {
    setData(file);
  }, [file]);

  const onDelete = (e: any) => {
    e.preventDefault();
    deleteDocument(file.id);
  };

  const onQuote = (e: any) => {
    e.preventDefault();
    handleQuote?.(data);
  };

  const handleDownload = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (isLoading) return;

    setIsLoading(true);
    try {
      const res = await downloadDocument(auditId, data.id);
      const result = await downloadFileFromURL(res, data.name || data.document);

      if (!result) window.open(res, "_blank");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateDataName = (name: string) => {
    setData((prevState: any) => ({
      ...prevState,
      name,
    }));
  };

  const handleReplaceDocument = () => {
    setIsMenuOpen(false);
    setIsReplacePopupOpen(true);
  };

  const handleOpenActionMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsMenuOpen(true);
    setIsReplacePopupOpen(false);
  };

  const actionItems = [
    {
      key: "1",
      icon: <RepeatIcon />,
      onClick: handleReplaceDocument,
      label: t("replace_document"),
    },
  ];
  {
    console.log(data);
  }

  return (
    <Link
      to={href || `/dashboard/${auditId}/documents/${file.id}`}
      target='_blank'
      className={styles.root}
      onClick={onClick}
    >
      <FileExtension document={data.document || data.name} />
      <div className={styles.info}>
        <div className={styles.infoTop}>
          <EditableDocumentName
            size='small'
            className={styles.name}
            id={data.id}
            name={data.name}
            onSave={handleUpdateDataName}
          />
          {isUploading && (
            <span className={styles.size}>
              {getHumanFileSize(progressPercentage * data.size, false)}/
              {getHumanFileSize(data.size)}
            </span>
          )}
        </div>
        {isUploading ? (
          <div className={styles.progressWrapper}>
            <div className={styles.progress} style={{ width: `${progress}%` }} />
          </div>
        ) : (
          <div className={styles.infoBot}>
            <span className={styles.date}>
              {dayjs(file.addedAt || file.lastModifiedDate).format("D MMM YYYY HH:mm")}
            </span>
          </div>
        )}
      </div>

      {isUploading || (
        <div className={styles.actions}>
          {handleQuote && <QuoteIcon onClick={onQuote} />}
          {deleteDocument && <TrashIcon onClick={onDelete} />}
          {data.id && (
            <>
              <DownloadRoundedIcon color='red' onClick={handleDownload} />
              <Dropdown
                onOpenChange={setIsMenuOpen}
                open={isMenuOpen}
                menu={{ items: actionItems }}
                trigger={["click"]}
              >
                <Button type='text' icon={<ThreeDotsIcon />} onClick={handleOpenActionMenu} />
              </Dropdown>
              <ReplaceDocumentPopover
                documentId={data.id}
                open={isReplacePopupOpen}
                onOpenChange={setIsReplacePopupOpen}
                onBack={() => setIsReplacePopupOpen(false)}
              >
                <span style={{ display: "none" }} />
              </ReplaceDocumentPopover>
            </>
          )}
        </div>
      )}
    </Link>
  );
};

export default AttachmentRow;
